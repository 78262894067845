

















































































import { Component, Prop, Vue, Emit } from "vue-property-decorator";
import DatetimePicker from 'vuetify-datetime-picker';
import formatter from "@/mixins/formatter";

Vue.use(DatetimePicker)

@Component
export default class TransactionConfig extends Vue {
    @Prop() modelValue!: string; // start_date prop for v-model
    @Prop() formattedStartVariation!: string;
    @Prop() typeTransaction!: number;
    @Prop() hasAction!: boolean;
    localStartDate = null; // local state for start_date
    localTypeTransaction = this.typeTransaction;
    created(){
        console.log("startVariatioon",this.formattedStartVariation)
    }
    @Emit('update:transactionDate')
    onChangePicker() {
        return this.localStartDate;
    }

    // Emit updated transaction type to parent
    @Emit('update:typeTransaction')
    setActionType(type: number) {
        return type;
    }
}

