
























































































































































































































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import formatter from "@/mixins/formatter";
import { MsiIndex } from "@/smartmsi";
import getUnicodeFlagIcon from "country-flag-icons/unicode";

import StockExchangeHistory from "@/components/stock/StockExchangeHistory.vue";
import Flashable from "@/components/stock/Flashable.vue";
import StockSamples from "@/components/stock/StockSamples.vue";
import MsiIndexChart from "@/components/stock/MsiIndexChart.vue";
import { AxiosResponse } from "axios";
import StockExchangeDetails from "@/components/stock/StockExchangeDetails.vue";


@Component({
  components: { StockExchangeDetails, MsiIndexChart, StockSamples, Flashable, StockExchangeHistory },
  mixins: [formatter]
})
export default class StockView extends Vue {

  @Prop() indexId!: number;

  startYear = '';
  endYear = '';

  currentTab = 0;
  tabl = 0;

  toggleBase = 0;

  nbrVersion = 0;
  msiYear = null;

  index: Partial<MsiIndex> = {};
  loading = true;

  withMsi = false;
  msiBase = 0;
  lastVersionYear = null
  live = false;

  last = [this.toggleBase == 0 ? 0 : 20000, 0];


  samples = [{
    '3M': 0,
    '6M': 0,
    'JAN': 0,
    'YTD': 0,
    'ALL': this.toggleBase == 0 ? this.index.stockExchange?.last.now : 20000
  }, {
    '3M': 0,
    '6M': 0,
    'JAN': 0,
    'YTD': 0,
    'ALL': this.index.stockExchange?.last.now
  }
  ];

  @Watch("toggleBase")
  onSourceChanged(newValue) {
    this.msiBase = newValue ? 20000 : 0
  }

  flag(code: string) {
    return getUnicodeFlagIcon(code);
  }

  get msiversion() {
    // Find the index of the last element with hasPercentage > 0
    const lastIndex = this.index.msiIndexVersions.reduceRight((acc, obj, index) => {
      if (obj.hasPercentage > 0 && acc === -1) {
        acc = index;
      }
      return acc;
    }, -1);

    return lastIndex;

  }
  formatDate(dateString) {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-CA'); // This will format the date as YYYY-MM-DD
  }
  get msiComponents() {
    this.nbrVersion = this.index.msiIndexVersions?.length - 1;
    const filtered = this.index.msiIndexVersions[this.msiversion]?.msiIndexCompositions.filter(entry => entry.percentage > 0);

    this.msiYear = this.index.msiIndexVersions[this.msiversion]?.year;
    return filtered;
  }

  get compositionSmartChunks() {
    const versionLength = this.index.msiIndexVersions?.length || 0;
    this.nbrVersion = versionLength - 1;
    const c = this.index.msiIndexVersions[versionLength - 1].msiIndexCompositions
    if (c) {
      const l = c.length;
      const chunks = [
        c.slice(0, l / 2),
        c.slice(l / 2),
      ];
      return chunks;
    }
    return [];
  }
  getPreviousValidScore(startVariationDate: any, scores: any) {
    let year = startVariationDate - 1; // Start from the year before the given date
    const minYear = Math.min(...Object.keys(scores).map(Number)); // Find the earliest year in scores

    while (year >= minYear) {
      if (scores[year]) {
        const score = scores[year].global; // Access the 'global' score
        if (score !== 0 && score !== null) {
          return score.toFixed(2); // Return the valid score
        }
      }
      year--; // Move to the previous year
    }
    return null; // Return null if no valid score is found
  }
  getNextValidScore(startVariationDate: any, scores: any) {
    let year = startVariationDate + 1; // Start from the year after the given date
    const maxYear = Math.max(...Object.keys(scores).map(Number)); // Find the latest year in scores

    while (year <= maxYear) {
      if (scores[year]) {
        const score = scores[year].global; // Access the 'global' score
        if (score !== 0 && score !== null) {
          return score; // Return the valid score
        }
      }
      year++; // Move to the next year
    }
    return null; // Return null if no valid score is found
  }
  getScore(companyYear, scores) {
    let score = 0
    score = this.getPreviousValidScore(companyYear, scores);

    // If no valid score was found, get the next valid score
    if (score === null) {
      score = this.getNextValidScore(companyYear, scores);
    }
    if (score === null) {
      return 'N/A'
    }
    return score
  }
  get compositionChunks() {
    const versionLength = this.index.stockExchange?.stockExchangeVersions?.length;
    this.lastVersionYear = this.index.stockExchange?.stockExchangeVersions[versionLength - 1].year;
    const c = this.index.stockExchange?.stockExchangeVersions[versionLength - 1].stockExchangeComposition;
    if (c) {
      const l = c.length;
      const chunks = [
        c.slice(0, l / 2),
        c.slice(l / 2),
      ];
      return chunks;
    }
    return [];
  }

  setData(d: Record<string, Record<string, number>[]>) {
    this.samples = d.samples;
    this.last = d.last;
  }

  reloadCompoInterval = 0;
  @Watch("live")
  setLive(live: boolean) {
    if (live) {
      this.reloadCompoInterval = window.setInterval(() => {
        this.loadMsiIndex();
      }, 3000);
    } else {
      window.clearInterval(this.reloadCompoInterval);
    }
  }

  beforeDestroy() {
    window.clearInterval(this.reloadCompoInterval);
  }

  isValidComposition() {
    return (
      this.index.msiIndexVersions[this.nbrVersion]?.msiIndexCompositions?.length > 0
    );
  }
  loadMsiIndex() {
    return this.$api.get(`msi-index/${this.indexId}?expand=msiIndexVersions.msiIndexCompositions.company.country,stockExchange.stockExchangeVersions.stockExchangeComposition.company.country`).then((res: AxiosResponse<MsiIndex>) => {
      this.index = res.data;
      if (this.index.stockExchange) {
        const now = this.index.stockExchange.last.now;
        this.last = [
          now, //this.toggleBase == 0 ? now : 20000,
          now
        ];
      }
    });
  }

  truncate(text: string, length: number) {
    if (text.length <= length + 3) {
      return text;
    }

    return text.substr(0, length) + ' ..';
  }

  created() {
    this.loadMsiIndex().then(() => {
      this.loading = false;
    });
  }

}
