var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        { staticClass: "mb-2" },
        [
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c("card", [
                _c("div", { staticClass: "card-header" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "card-title selected-item d-flex justify-space-between portfolio-card-header",
                    },
                    [
                      _c("div", [
                        _c("div", [
                          _c("b", [
                            _vm._v(
                              _vm._s(_vm.$t("Portefeuille")) +
                                " : " +
                                _vm._s(_vm.portfolioName) +
                                " ( " +
                                _vm._s(_vm.portfolioDevise) +
                                " )"
                            ),
                          ]),
                        ]),
                        _c("div", [
                          _vm.portfolioResult != null
                            ? _c("b", [
                                _vm._v(
                                  _vm._s(_vm.$t("Result")) +
                                    " : " +
                                    _vm._s(_vm.portfolioResult) +
                                    " " +
                                    _vm._s(
                                      _vm.getCurrencySymbol(
                                        _vm.portfolioCurrency
                                      )
                                    )
                                ),
                              ])
                            : _vm._e(),
                        ]),
                        _c("div", [
                          _vm.portfolioPerformance != null
                            ? _c("b", [
                                _vm._v("Performance :"),
                                _c(
                                  "span",
                                  {
                                    class:
                                      _vm.portfolioPerformance > 0
                                        ? "positive-income"
                                        : "negative-income",
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.portfolioPerformance) +
                                        " %"
                                    ),
                                  ]
                                ),
                              ])
                            : _vm._e(),
                        ]),
                      ]),
                      _c("div", { staticClass: "portfolio-budget" }, [
                        _c("b", [
                          _vm._v(
                            _vm._s(_vm.$t("Budget Initial")) +
                              " : " +
                              _vm._s(_vm.portfolioBudget) +
                              " " +
                              _vm._s(
                                _vm.getCurrencySymbol(_vm.portfolioCurrency)
                              )
                          ),
                        ]),
                      ]),
                    ]
                  ),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "mb-2" },
        [
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c(
                "card",
                [
                  _c("div", { staticClass: "card-header" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "card-title selected-item d-flex justify-space-between portfolio-card-header",
                      },
                      [
                        _c("b", [_vm._v("Versions ")]),
                        _c("span", { staticClass: "text-right" }, [
                          _c("b", [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("Cash Disponible")) +
                                " : " +
                                _vm._s(
                                  _vm.sortedPortfolioVersions.length > 0
                                    ? parseFloat(
                                        _vm.sortedPortfolioVersions[
                                          _vm.sortedPortfolioVersions.length - 1
                                        ].cash_balance
                                      ).toFixed(3)
                                    : parseFloat(_vm.portfolio.budget).toFixed(
                                        3
                                      )
                                ) +
                                " " +
                                _vm._s(
                                  _vm.getCurrencySymbol(_vm.portfolioCurrency)
                                ) +
                                " "
                            ),
                          ]),
                        ]),
                      ]
                    ),
                  ]),
                  _c(
                    "v-card-text",
                    [
                      [
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: 2 } },
                              [
                                _c(
                                  "v-tabs",
                                  {
                                    attrs: { color: "primary", vertical: "" },
                                    model: {
                                      value: _vm.tabl,
                                      callback: function ($$v) {
                                        _vm.tabl = $$v
                                      },
                                      expression: "tabl",
                                    },
                                  },
                                  _vm._l(
                                    _vm.sortedPortfolioVersions,
                                    function (itemx, idx) {
                                      return _c("v-tab", { key: idx }, [
                                        _vm._v(
                                          " " + _vm._s(itemx["name"]) + " "
                                        ),
                                      ])
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: 10 } },
                              [
                                _c(
                                  "v-tabs-items",
                                  {
                                    model: {
                                      value: _vm.tabl,
                                      callback: function ($$v) {
                                        _vm.tabl = $$v
                                      },
                                      expression: "tabl",
                                    },
                                  },
                                  _vm._l(
                                    _vm.sortedPortfolioVersions,
                                    function (itemx, idx) {
                                      return _c(
                                        "v-tab-item",
                                        { key: idx },
                                        [
                                          _c("h5", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t("date de création du")
                                              ) +
                                                " " +
                                                _vm._s(itemx["name"]) +
                                                " : " +
                                                _vm._s(itemx.date_version)
                                            ),
                                          ]),
                                          _c(
                                            "v-row",
                                            [
                                              _c(
                                                "v-col",
                                                [
                                                  _c(
                                                    "v-simple-table",
                                                    { attrs: { dense: "" } },
                                                    [
                                                      _c("thead", [
                                                        _c("tr", [
                                                          _c("th", [
                                                            _vm._v("NAME"),
                                                          ]),
                                                          _c("th", [
                                                            _vm._v("ACTION"),
                                                          ]),
                                                          _c("th", [
                                                            _vm._v("CLOSE"),
                                                          ]),
                                                          _c("th", [
                                                            _vm._v("VALO"),
                                                          ]),
                                                          _c("th", [
                                                            _vm._v("SOLIDITE"),
                                                          ]),
                                                          _c("th", [
                                                            _vm._v(
                                                              "PERFORMANCE"
                                                            ),
                                                          ]),
                                                          _c("th", [
                                                            _vm._v("SCORE"),
                                                          ]),
                                                          _c(
                                                            "th",
                                                            {
                                                              staticStyle: {
                                                                "text-align":
                                                                  "right",
                                                              },
                                                            },
                                                            [_vm._v("%")]
                                                          ),
                                                        ]),
                                                      ]),
                                                      _c(
                                                        "tbody",
                                                        _vm._l(
                                                          itemx.portfolioComposition,
                                                          function (
                                                            component,
                                                            ids
                                                          ) {
                                                            return _c(
                                                              "tr",
                                                              { key: ids },
                                                              [
                                                                [
                                                                  _c(
                                                                    "th",
                                                                    {
                                                                      staticStyle:
                                                                        {
                                                                          "font-size":
                                                                            ".8em",
                                                                        },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "span",
                                                                        {
                                                                          staticClass:
                                                                            "mr-1",
                                                                          staticStyle:
                                                                            {
                                                                              "font-size":
                                                                                "16px",
                                                                            },
                                                                        }
                                                                      ),
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            component.company.name.toUpperCase()
                                                                          ) +
                                                                          " "
                                                                      ),
                                                                      _c(
                                                                        "small",
                                                                        [
                                                                          _vm._v(
                                                                            " (" +
                                                                              _vm._s(
                                                                                component
                                                                                  .company
                                                                                  .symbol
                                                                              ) +
                                                                              ")"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _c("td", [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          component.nbr_action
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                  ]),
                                                                  _c("td", [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          parseFloat(
                                                                            component.close
                                                                          ).toFixed(
                                                                            3
                                                                          )
                                                                        ) +
                                                                        " " +
                                                                        _vm._s(
                                                                          _vm.getCurrencySymbol(
                                                                            component.company_currency
                                                                          )
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                  ]),
                                                                  _c("td", [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          parseFloat(
                                                                            component.valo
                                                                          ).toFixed(
                                                                            3
                                                                          )
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                  ]),
                                                                  _c("td", [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          parseFloat(
                                                                            component.solidite
                                                                          ).toFixed(
                                                                            3
                                                                          )
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                  ]),
                                                                  _c("td", [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          parseFloat(
                                                                            component.performance
                                                                          ).toFixed(
                                                                            3
                                                                          )
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                  ]),
                                                                  _c("td", [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          parseFloat(
                                                                            component.score
                                                                          ).toFixed(
                                                                            3
                                                                          )
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                  ]),
                                                                  _c(
                                                                    "td",
                                                                    {
                                                                      staticStyle:
                                                                        {
                                                                          "text-align":
                                                                            "right",
                                                                        },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            parseFloat(
                                                                              component.percentage
                                                                            ).toFixed(
                                                                              2
                                                                            )
                                                                          ) +
                                                                          " "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                              ],
                                                              2
                                                            )
                                                          }
                                                        ),
                                                        0
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-row",
                                            [_c("v-col", [_c("hr")])],
                                            1
                                          ),
                                          _c(
                                            "v-row",
                                            [
                                              _c(
                                                "v-col",
                                                [
                                                  _c(
                                                    "v-simple-table",
                                                    { attrs: { dense: "" } },
                                                    [
                                                      _c("thead", [
                                                        _c("tr", [
                                                          _c("th", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "Transaction Balance"
                                                                )
                                                              )
                                                            ),
                                                          ]),
                                                          _c("th", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "Capitalization"
                                                                )
                                                              )
                                                            ),
                                                          ]),
                                                          _c("th", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "Cash Balance"
                                                                )
                                                              )
                                                            ),
                                                          ]),
                                                          _c("th", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "Global Valuation"
                                                                )
                                                              )
                                                            ),
                                                          ]),
                                                          _c("th", [
                                                            _vm._v("index"),
                                                          ]),
                                                        ]),
                                                      ]),
                                                      _c("tbody", [
                                                        _c("tr", [
                                                          _c("td", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.formatNumber(
                                                                  itemx[
                                                                    "total_transactions"
                                                                  ]
                                                                )
                                                              ) +
                                                                " " +
                                                                _vm._s(
                                                                  _vm.getCurrencySymbol(
                                                                    _vm.portfolioCurrency
                                                                  )
                                                                ) +
                                                                " "
                                                            ),
                                                          ]),
                                                          _c("td", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.formatNumber(
                                                                  itemx[
                                                                    "capitalization"
                                                                  ]
                                                                )
                                                              ) +
                                                                " " +
                                                                _vm._s(
                                                                  _vm.getCurrencySymbol(
                                                                    _vm.portfolioCurrency
                                                                  )
                                                                )
                                                            ),
                                                          ]),
                                                          _c("td", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.formatNumber(
                                                                  itemx[
                                                                    "cash_balance"
                                                                  ]
                                                                )
                                                              ) +
                                                                " " +
                                                                _vm._s(
                                                                  _vm.getCurrencySymbol(
                                                                    _vm.portfolioCurrency
                                                                  )
                                                                )
                                                            ),
                                                          ]),
                                                          _c("td", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.formatNumber(
                                                                  itemx[
                                                                    "global_valuation"
                                                                  ]
                                                                )
                                                              ) +
                                                                " " +
                                                                _vm._s(
                                                                  _vm.getCurrencySymbol(
                                                                    _vm.portfolioCurrency
                                                                  )
                                                                )
                                                            ),
                                                          ]),
                                                          _c("td", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.formatNumber(
                                                                  itemx["index"]
                                                                )
                                                              ) + " "
                                                            ),
                                                          ]),
                                                        ]),
                                                      ]),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "mb-2" },
        [
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c(
                "card",
                [
                  _c("div", { staticClass: "card-header" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "card-title selected-item d-flex justify-space-between portfolio-card-header",
                      },
                      [
                        _c("div", [
                          _c("b", [
                            _vm._v(_vm._s(_vm.$t("Transactions")) + " "),
                          ]),
                        ]),
                        _c(
                          "div",
                          [
                            _c(
                              "v-btn",
                              {
                                attrs: { text: "", color: "primary" },
                                on: {
                                  click: function ($event) {
                                    return _vm.addTransactionDispatch()
                                  },
                                },
                              },
                              [
                                _c(
                                  "v-icon",
                                  { attrs: { left: "", small: "" } },
                                  [_vm._v("fa fa-plus")]
                                ),
                                _vm._v(
                                  " " + _vm._s(_vm.$t("add new transaction"))
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]),
                  _c(
                    "v-card-text",
                    [
                      [
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "12" } },
                              [
                                _c("v-simple-table", [
                                  _c("thead", [
                                    _c("tr", [
                                      _c("th", [
                                        _vm._v(_vm._s(_vm.$t("Name"))),
                                      ]),
                                      _c("th", [
                                        _vm._v(
                                          _vm._s(_vm.$t("Actions Number"))
                                        ),
                                      ]),
                                      _c("th", [
                                        _vm._v(_vm._s(_vm.$t("Price"))),
                                      ]),
                                      _c("th", [
                                        _vm._v(_vm._s(_vm.$t("Close"))),
                                      ]),
                                      _c("th", [
                                        _vm._v(_vm._s(_vm.$t("Fees"))),
                                      ]),
                                      _c("th", [_vm._v("Valorisation")]),
                                      _c("th", [
                                        _vm._v(_vm._s(_vm.$t("Action Date"))),
                                      ]),
                                      _c("th", [_vm._v("Type")]),
                                    ]),
                                  ]),
                                  _c(
                                    "tbody",
                                    _vm._l(
                                      _vm.portfolioActions,
                                      function (action) {
                                        return _c("tr", { key: action.id }, [
                                          _c("td", [
                                            _vm._v(
                                              _vm._s(action["company"].name)
                                            ),
                                          ]),
                                          _c("td", [
                                            _vm._v(
                                              _vm._s(action["nbr_action"])
                                            ),
                                          ]),
                                          _c("td", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.formatNumber(
                                                  action["price"]
                                                )
                                              ) +
                                                " " +
                                                _vm._s(
                                                  _vm.getCurrencySymbol(
                                                    action["company_currency"]
                                                  )
                                                )
                                            ),
                                          ]),
                                          _c("td", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.formatNumber(
                                                  action["close"]
                                                )
                                              ) +
                                                " " +
                                                _vm._s(
                                                  _vm.getCurrencySymbol(
                                                    action["company_currency"]
                                                  )
                                                )
                                            ),
                                          ]),
                                          _c("td", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.formatNumber(action["fees"])
                                              ) +
                                                " " +
                                                _vm._s(
                                                  _vm.getCurrencySymbol(
                                                    action["company_currency"]
                                                  )
                                                )
                                            ),
                                          ]),
                                          _c("td", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.formatNumber(action["valo"])
                                              ) +
                                                " " +
                                                _vm._s(
                                                  _vm.getCurrencySymbol(
                                                    action["company_currency"]
                                                  )
                                                )
                                            ),
                                          ]),
                                          _c("td", [
                                            _vm._v(
                                              _vm._s(action["date_variation"])
                                            ),
                                          ]),
                                          _c(
                                            "td",
                                            [
                                              action["type"] > 0
                                                ? _c(
                                                    "v-icon",
                                                    {
                                                      attrs: { color: "green" },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            "mdi-arrow-up"
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  )
                                                : _c(
                                                    "v-icon",
                                                    { attrs: { color: "red" } },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            "mdi-arrow-down"
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                            ],
                                            1
                                          ),
                                        ])
                                      }
                                    ),
                                    0
                                  ),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }