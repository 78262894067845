var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { md: "5" } },
            [
              _c(
                "v-card",
                [
                  _c("v-card-title", [_vm._v(_vm._s(_vm.$t("stock.title")))]),
                  _c(
                    "v-btn",
                    {
                      staticClass: "add-index-btn",
                      attrs: { right: "" },
                      on: {
                        click: function ($event) {
                          _vm.addDialog = true
                        },
                      },
                    },
                    [_vm._v("Ajouter")]
                  ),
                  _c(
                    "v-card-text",
                    [
                      _c("grid-view", {
                        ref: "grid",
                        attrs: {
                          headers: _vm.headers,
                          endpoint: "msi-index?expand=stockExchange.country",
                          model: "StockExchange",
                          loading: _vm.loading,
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "item.stockExchange.name",
                            fn: function (ref) {
                              var item = ref.item
                              return [
                                item.stockExchange.country
                                  ? _c("CountryFlag", {
                                      attrs: {
                                        code: item.stockExchange.country.code,
                                      },
                                    })
                                  : _vm._e(),
                                _vm._v(
                                  " " + _vm._s(item.stockExchange.name) + " "
                                ),
                              ]
                            },
                          },
                          {
                            key: "item.stockExchange.country.name",
                            fn: function (ref) {
                              var item = ref.item
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.translate(
                                        item.stockExchange.country.name
                                      )
                                    ) +
                                    " "
                                ),
                              ]
                            },
                          },
                          {
                            key: "item.stockExchange.last.now",
                            fn: function (ref) {
                              var item = ref.item
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(item.stockExchange.last.now) +
                                    " "
                                ),
                              ]
                            },
                          },
                          {
                            key: "item._actions",
                            fn: function (ref) {
                              var item = ref.item
                              return [
                                _c(
                                  "v-btn",
                                  {
                                    attrs: { icon: "", small: "" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.viewIndex(item)
                                      },
                                    },
                                  },
                                  [
                                    _c("v-icon", { attrs: { small: "" } }, [
                                      _vm._v("mdi-eye"),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-btn",
                                  {
                                    attrs: { icon: "", small: "" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.activateIndex(item)
                                      },
                                    },
                                  },
                                  [
                                    item.stockExchange.active
                                      ? _c("v-icon", { attrs: { small: "" } }, [
                                          _vm._v("fa fa-unlock-alt"),
                                        ])
                                      : _c("v-icon", { attrs: { small: "" } }, [
                                          _vm._v("fa fa-lock"),
                                        ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-btn",
                                  {
                                    attrs: { icon: "", small: "" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.viewSetting(
                                          item,
                                          item.stockExchange.id,
                                          item.stockExchange.name
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c("v-icon", { attrs: { small: "" } }, [
                                      _vm._v("fa fa-cogs"),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-tooltip",
                                  {
                                    staticClass: "text-center",
                                    attrs: { bottom: "", color: "black" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function (ref) {
                                            var on = ref.on
                                            var attrs = ref.attrs
                                            return [
                                              _c(
                                                "v-btn",
                                                _vm._g(
                                                  _vm._b(
                                                    {
                                                      attrs: {
                                                        icon: "",
                                                        small: "",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.changeMode(
                                                            item
                                                          )
                                                        },
                                                      },
                                                    },
                                                    "v-btn",
                                                    attrs,
                                                    false
                                                  ),
                                                  on
                                                ),
                                                [
                                                  item.stockExchange.mode
                                                    ? _c(
                                                        "v-icon",
                                                        {
                                                          staticClass:
                                                            "auto-mode",
                                                          attrs: { small: "" },
                                                        },
                                                        [_vm._v("fa fa-sync")]
                                                      )
                                                    : _c(
                                                        "v-icon",
                                                        {
                                                          staticClass:
                                                            "manual-mode",
                                                          attrs: { small: "" },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "fa fa-hand-paper"
                                                          ),
                                                        ]
                                                      ),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  },
                                  [
                                    item.stockExchange.mode
                                      ? _c(
                                          "div",
                                          { staticClass: "text-center" },
                                          [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "This index is in automatic mode."
                                                  )
                                                )
                                              ),
                                            ]),
                                            _c("br"),
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "Switching to manual mode will disable automatic updates and require manual intervention for changes."
                                                  )
                                                )
                                              ),
                                            ]),
                                          ]
                                        )
                                      : _c(
                                          "div",
                                          { staticClass: "text-center" },
                                          [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "This index is in manual mode."
                                                  )
                                                )
                                              ),
                                            ]),
                                            _c("br"),
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "Switching to automatic mode will streamline updates and ensure smart versions are generated periodically."
                                                  )
                                                )
                                              ),
                                            ]),
                                          ]
                                        ),
                                  ]
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { md: "7" } },
            [
              _c(
                "v-card",
                [
                  _c("v-card-title", [_vm._v("World Map")]),
                  _c(
                    "v-card-text",
                    [
                      !_vm.loading
                        ? _c("Map", {
                            attrs: { indices: _vm.indices },
                            on: { seiClick: _vm.viewIndex },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "600" },
          model: {
            value: _vm.addDialog,
            callback: function ($$v) {
              _vm.addDialog = $$v
            },
            expression: "addDialog",
          },
        },
        [_c("msi-index-form", { on: { done: _vm.onIndexAdded } })],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }