





































































































































































































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { setPageTitle } from "@/utils/meta";
import Card from "@/components/Card.vue";
import formatter from "@/mixins/formatter";
import PortfolioView from "./View.vue";
@Component({
    components: { Card },
    mixins: [formatter],
})
export default class PortefeuilleSetting extends Vue {
    @Prop() portfolioId!: number;
    @Prop() portfolioName!: string;
    @Prop() portfolioDevise!: string;
    portfolio = null
    openDialogVariationDate = false
    startVariation = null;
    selectedCompanies: any = [];
    exist = true;
    sortedPortfolioVersions = []
    tabl = 0;
    portfolioActions = []
    lastVersion = null
    portfolioCurrency = null
    portfolioBudget = null
    portfolioResult = null
    portfolioPerformance = null


    @Watch("portfolio")
    onChangeportfolio() {
        this.sortedPortfolioVersions = (this.portfolio.portfolioVersions || []).sort((a, b) => {
            // Ensure date_version is a valid date string
            const dateA = new Date(a.date_version).getTime();
            const dateB = new Date(b.date_version).getTime();

            // Handle potential invalid date scenarios
            return isNaN(dateA) || isNaN(dateB) ? 0 : dateA - dateB;
        });
    }

    created() {
        setPageTitle("Portfolio Setting ");
        this.loadPortfolioInfo()
        this.loadActions()
    }


    loadPortfolioInfo() {
        return this.$api
            .get(
                `portfolio/${this.portfolioId}?expand=portfolioVersions.portfolioComposition.company`
            )
            .then(res => {
                this.portfolio = res.data
                console.log("this.portfolio", this.portfolio)
                const dateStartPortfolio = res.data.start_date_variation;
                // console.log("datestart", dateStartPortfolio)
                this.portfolioCurrency = res.data.devise
                this.portfolioBudget = parseFloat(res.data.budget).toFixed(3)
                if (dateStartPortfolio) {
                    this.openDialogVariationDate = false
                    const date = new Date(dateStartPortfolio);
                    // Add 1 hour (3600000 milliseconds)
                    date.setHours(date.getHours() + 1);
                    // Format the date to 'YYYY-MM-DD HH:MM'
                    const formattedDate = date.toISOString().slice(0, 16).replace('T', ' ');
                    this.startVariation = formattedDate;
                    console.log(formattedDate)
                } else {
                    this.openDialogVariationDate = true
                }
                if (
                    res.data.portfolioVersions &&
                    res.data.portfolioVersions.length > 0
                ) {
                    const lastPortfolioVersion = res.data.portfolioVersions
                        .slice()
                        .sort((a, b) => new Date(a.date_version) - new Date(b.date_version))
                        .pop();
                    this.lastVersion = lastPortfolioVersion;
                    const result = parseFloat(lastPortfolioVersion.capitalization) + parseFloat(lastPortfolioVersion.total_transactions) + parseFloat(lastPortfolioVersion.cash_balance) - parseFloat(this.portfolioBudget)
                    console.log("result", result)
                    this.portfolioResult = parseFloat(result).toFixed(3)
                    this.portfolioPerformance = (this.portfolioResult * 100) / this.portfolioBudget
                    this.exist = true;
                } else {
                    // Handle case where portfolioVersions is empty
                    this.selectedCompanies = [];
                    this.exist = false;
                }
            });
    }
    loadActions() {
        return this.$api.get(`portfolio/get-actions?portfolio=${this.portfolioId}`).then(res => {
            this.portfolioActions = res.data;
            // console.log("countrys",res.data)
        });
    }
    formatNumber(value: string) {
        return Number(value).toFixed(3);
    }
    getCurrencySymbol(currency: any) {
        if (currency != null) {
            const currencySymbols = {
                'EUR': '€',
                'AUD': 'A$', // Australian Dollar symbol
                'TRY': '₺',
                'SEK': 'kr',
                'INR': '₹',
                'JPY': '¥',
                'KRW': '₩',
                'USD': '$',
                'NOK': 'kr',
                'CNY': '¥',
                'CAD': 'CA$',
                'THB': '฿',
                'GBP': '£',
                'HKD': 'HK$',
                'TWD': 'NT$',
                'ZAR': 'R',
                'IDR': 'Rp',
                'BRL': 'R$',
                'PLN': 'zł',
                'SGD': 'S$',
                'ILS': '₪',
                'QAR': '﷼',
                'SAR': '﷼',
                'DKK': 'kr',
                'CLP': 'CLP$',
                'MXN': 'MX$',
                'CHF': 'CHF',
                'NZD': 'NZ$',
                'MYR': 'RM',
                'ISK': 'kr',
                'ARS': '$',
                'CZK': 'Kč',
                'HUF': 'Ft',
                'AED': 'د.إ',
                'KWD': 'د.ك',
                'TND': 'د.ت'
            };
            return currencySymbols[currency] || currency; // Fallback to currency code if symbol is not found
        } else {
            return '€'
        }
    }
    addTransactionDispatch() {
        const route = "/"
        this.$router.push(`/portfolio/${this.portfolioId}/add-transaction/${this.portfolioName}/${this.portfolioDevise}`);
    }
}
