var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "px-5 pt-5" },
    [
      _c("v-card-title", [_vm._v(" Import Versions ")]),
      _c(
        "v-card-text",
        [
          _c(
            "v-row",
            { staticClass: "ma-2" },
            [
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c("v-file-input", {
                    attrs: {
                      "single-line": "",
                      label: "Version file",
                      filled: "",
                      "prepend-icon": "fa-file-excel",
                    },
                    model: {
                      value: _vm.versionFile,
                      callback: function ($$v) {
                        _vm.versionFile = $$v
                      },
                      expression: "versionFile",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c("upload-error", { attrs: { error: _vm.error } }),
          _vm.success
            ? [
                _c(
                  "v-alert",
                  { attrs: { prominent: "", type: "success" } },
                  [
                    _c(
                      "v-row",
                      { attrs: { align: "center" } },
                      [
                        _c(
                          "v-col",
                          {
                            staticClass: "grow",
                            staticStyle: { "white-space": "pre-wrap" },
                          },
                          [
                            _c("b", [_vm._v(_vm._s(_vm.successMessage))]),
                            _c("br"),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            : _vm._e(),
          _c(
            "v-btn",
            {
              staticClass: "ma-2",
              attrs: { disabled: !_vm.canUpload, color: "primary" },
              on: {
                click: function ($event) {
                  return _vm.uploadVersions()
                },
              },
            },
            [
              _c("v-icon", { attrs: { left: "", dark: "" } }, [
                _vm._v(" mdi-upload "),
              ]),
              _vm._v(" Import "),
            ],
            1
          ),
          _vm.uploadingConfig && _vm.uploadPercentage < 100
            ? _c(
                "div",
                { staticStyle: { display: "inline-block" } },
                [
                  _c("v-progress-circular", {
                    attrs: { value: _vm.uploadPercentage },
                  }),
                  _vm._v(" Uploading .. "),
                ],
                1
              )
            : _vm.uploadingConfig
            ? _c(
                "div",
                { staticStyle: { display: "inline-block" } },
                [
                  _c("v-progress-circular", { attrs: { indeterminate: "" } }),
                  _vm._v(" Parsing config .. "),
                ],
                1
              )
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }