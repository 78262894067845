

































import { Component, Prop, Vue, Emit, Watch } from "vue-property-decorator";
import DatetimePicker from 'vuetify-datetime-picker';
import formatter from "@/mixins/formatter";
import Card from "@/components/Card.vue";
import UploadError from "@/components/UploadError.vue";
Vue.use(DatetimePicker)

@Component({
    components: { Card, UploadError },
    mixins: [formatter],
})
export default class CompanySell extends Vue {
    @Prop() lastVersion!: any; // start_date prop for v-model

    companies = []
    company = []
    singleSelect = false
    get headers() {
        return [
            // { text: "", value: "_actions" },

            { text: this.$i18n.t("models.company.name"), value: "name" },
            {
                text: this.$i18n.t("ISIN"),
                value: "profil_fmp.isin"
            },
            {
                text: this.$i18n.t("score"),
                filterable: false,
                value: "score",
            },
            {
                text: this.$i18n.t("performance"),  // New "Close" column
                filterable: false,
                value: "performance_score",

            },
            {
                text: this.$i18n.t("Solidité"),  // New "Nbr Action" column
                filterable: false,
                value: "solidity_score",
            },
            {
                text: this.$i18n.t("origin"),
                value: "origin",
            },
        ];
    }
    @Watch('company')
    onChangeSelectedCompany() {
        this.$emit('update:compoTransaction', this.company)
    }

    created() {
        this.mapLastVersionComposition()
        console.log("companies", this.companies)
    }

    mapLastVersionComposition() {
        console.log("last", this.lastVersion)
        this.lastVersion.portfolioComposition.forEach((item: any) => {
            this.companies.push(item.company);
        });
    }
}
