<template>
  <v-snackbar v-model="$store.state.snack.show" :color="$store.state.snack.color" :top="$store.state.snack.top" :right="$store.state.snack.right" :timeout="$store.state.snack.timeout">


    <div class="d-flex justify-content-space-between" >
      <div v-if="$store.state.snack.html" v-html="$store.state.snack.html"></div>
      <div v-else-if="$store.state.snack.text" v-text="$store.state.snack.text"></div>
      <v-icon size="16" @click="$store.state.snack.show = false">
        mdi-close-circle
      </v-icon>
    </div>
  </v-snackbar>
</template>

<script>
import { Vue, Component } from "vue-property-decorator";

@Component()
export default class Snack extends Vue {
}
</script>