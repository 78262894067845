var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _vm.loading
        ? [
            _c("v-row", { staticClass: "justify-content-center m-2" }, [
              _c(
                "div",
                { staticClass: "text-center m-2" },
                [
                  _c("v-progress-circular", {
                    attrs: { indeterminate: "", value: 20, size: 70 },
                  }),
                ],
                1
              ),
            ]),
          ]
        : [
            _c(
              "v-row",
              [
                _c(
                  "v-col",
                  { attrs: { cols: "12" } },
                  [
                    _c(
                      "v-card",
                      [
                        _c("v-card-title", [
                          _c("b", [
                            _vm._v(_vm._s(_vm.$t("Transaction Recap"))),
                          ]),
                        ]),
                        _c(
                          "v-card-text",
                          [
                            _c(
                              "v-row",
                              [
                                _c(
                                  "v-col",
                                  {
                                    staticClass: "d-flex align-items-center",
                                    attrs: { cols: "4" },
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(_vm.$t("Date debut variation")) +
                                          " :"
                                      ),
                                      _c("b", [
                                        _vm._v(
                                          _vm._s(_vm.formattedVariationDate)
                                        ),
                                      ]),
                                    ]),
                                  ]
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "4" } },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(_vm.$t("Transaction Type")) +
                                          " :"
                                      ),
                                    ]),
                                    _c(
                                      "v-chip",
                                      {
                                        staticClass: "ma-2",
                                        attrs: {
                                          color:
                                            _vm.selectedTransactionType.color,
                                          "text-color": "white",
                                          small: "",
                                        },
                                      },
                                      [
                                        _c(
                                          "v-avatar",
                                          { attrs: { left: "" } },
                                          [
                                            _c("v-icon", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.selectedTransactionType
                                                    .icon
                                                )
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.selectedTransactionType
                                                .description
                                            ) +
                                            " "
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c("v-col", { attrs: { cols: "4" } }, [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(_vm.$t("cash disponible")) + " : "
                                    ),
                                    _c("b", [
                                      _vm._v(
                                        _vm._s(_vm.currentBudget) +
                                          " " +
                                          _vm._s(
                                            _vm.getCurrencySymbol(
                                              _vm.portfolioCurrency
                                            )
                                          )
                                      ),
                                    ]),
                                  ]),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "v-row",
              _vm._l(_vm.transactions, function (company, index) {
                return _c(
                  "v-col",
                  { key: index, attrs: { cols: "12" } },
                  [
                    _c(
                      "v-card",
                      [
                        _c(
                          "v-card",
                          [
                            _c("v-card-title", [
                              _c("b", [
                                _vm._v(
                                  _vm._s(_vm.$t("Company")) +
                                    " " +
                                    _vm._s(company.name) +
                                    " - " +
                                    _vm._s(company.symbol)
                                ),
                              ]),
                            ]),
                            _c(
                              "v-card-text",
                              [
                                _c(
                                  "v-row",
                                  [
                                    _c("v-col", { attrs: { cols: "4" } }, [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.$t("Country")) + " : "
                                        ),
                                        _c("b", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.translate(
                                                company.country.name
                                              )
                                            ) + " "
                                          ),
                                        ]),
                                      ]),
                                    ]),
                                    _c("v-col", { attrs: { cols: "4" } }, [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.$t("Company currency")) +
                                            " : "
                                        ),
                                        _c("b", [
                                          _vm._v(
                                            _vm._s(company.profil_fmp.currency)
                                          ),
                                        ]),
                                      ]),
                                    ]),
                                    _c("v-col", { attrs: { cols: "4" } }, [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.$t("Company score")) +
                                            " : "
                                        ),
                                        _c("b", [
                                          _vm._v(
                                            _vm._s(
                                              parseFloat(company.score).toFixed(
                                                3
                                              )
                                            ) + " "
                                          ),
                                        ]),
                                      ]),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "v-card",
                          [
                            _c(
                              "v-card-title",
                              { staticClass: "transaction-details" },
                              [
                                _c("b", [
                                  _vm._v(_vm._s(_vm.$t("Transaction details"))),
                                ]),
                              ]
                            ),
                            _c(
                              "v-card-text",
                              [
                                _c(
                                  "v-row",
                                  [
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "6" } },
                                      [
                                        _c("v-text-field", {
                                          attrs: {
                                            label: _vm.$t(_vm.priceLabel),
                                            prefix: _vm.getCurrencySymbol(
                                              _vm.portfolioCurrency
                                            ),
                                          },
                                          on: {
                                            blur: function ($event) {
                                              return _vm.updatePrice(index)
                                            },
                                          },
                                          model: {
                                            value: company.price,
                                            callback: function ($$v) {
                                              _vm.$set(company, "price", $$v)
                                            },
                                            expression: "company.price",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "6" } },
                                      [
                                        _c("v-text-field", {
                                          attrs: {
                                            label: _vm.$t("Close"),
                                            prefix: _vm.getCurrencySymbol(
                                              _vm.portfolioCurrency
                                            ),
                                          },
                                          model: {
                                            value: company.close,
                                            callback: function ($$v) {
                                              _vm.$set(company, "close", $$v)
                                            },
                                            expression: "company.close",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-row",
                                  [
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "6" } },
                                      [
                                        _c("v-text-field", {
                                          attrs: { label: _vm.$t("nbrAction") },
                                          on: {
                                            blur: function ($event) {
                                              return _vm.updatePrice(index)
                                            },
                                          },
                                          model: {
                                            value: company.nbrAction,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                company,
                                                "nbrAction",
                                                $$v
                                              )
                                            },
                                            expression: "company.nbrAction",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "6" } },
                                      [
                                        _c("v-text-field", {
                                          attrs: {
                                            label: _vm.$t("Fees"),
                                            prefix: _vm.getCurrencySymbol(
                                              _vm.portfolioCurrency
                                            ),
                                          },
                                          on: {
                                            blur: function ($event) {
                                              return _vm.updatePrice(index)
                                            },
                                          },
                                          model: {
                                            value: company.fees,
                                            callback: function ($$v) {
                                              _vm.$set(company, "fees", $$v)
                                            },
                                            expression: "company.fees",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-row",
                                  [
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "6" } },
                                      [
                                        _c("v-text-field", {
                                          attrs: {
                                            readonly: "",
                                            label: _vm.$t("Total"),
                                            prefix: _vm.getCurrencySymbol(
                                              _vm.portfolioCurrency
                                            ),
                                          },
                                          model: {
                                            value: company.total,
                                            callback: function ($$v) {
                                              _vm.$set(company, "total", $$v)
                                            },
                                            expression: "company.total",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              }),
              1
            ),
            _c(
              "v-row",
              [
                _c(
                  "v-col",
                  { attrs: { cols: "12" } },
                  [
                    _vm.errorValidation
                      ? _c(
                          "v-alert",
                          {
                            attrs: {
                              dense: "",
                              icon: "mdi-alert",
                              value: _vm.alertDismiss,
                              type: "error",
                            },
                          },
                          [_vm._v(_vm._s(_vm.errorMessage) + " ")]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }