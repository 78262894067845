var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        { staticClass: "mb-2" },
        [
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c(
                "v-card",
                [
                  _c("v-card-title", [
                    _c("b", [_vm._v(_vm._s(_vm.$t("Add Transaction")))]),
                  ]),
                  _c(
                    "v-card-text",
                    [
                      _c(
                        "v-stepper",
                        {
                          model: {
                            value: _vm.st,
                            callback: function ($$v) {
                              _vm.st = $$v
                            },
                            expression: "st",
                          },
                        },
                        [
                          _c(
                            "v-stepper-header",
                            [
                              _c(
                                "v-stepper-step",
                                { attrs: { complete: _vm.st > 1, step: "1" } },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("Transaction Setup")) +
                                      " "
                                  ),
                                ]
                              ),
                              _c("v-divider"),
                              _c(
                                "v-stepper-step",
                                { attrs: { complete: _vm.st > 2, step: "2" } },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("Choose Company")) + " "
                                  ),
                                ]
                              ),
                              _c("v-divider"),
                              _c("v-stepper-step", { attrs: { step: "3" } }, [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("Complete the Transaction")) +
                                    " "
                                ),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "v-stepper-items",
                            [
                              _c(
                                "v-stepper-content",
                                { attrs: { step: "1" } },
                                [
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c(
                                            "v-card",
                                            {
                                              attrs: {
                                                elevation: "5",
                                                outlined: "",
                                                shaped: "",
                                                tile: "",
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "card-header" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "card-title selected-item d-flex justify-space-between portfolio-card-header",
                                                    },
                                                    [
                                                      _c("div", [
                                                        _c("div", [
                                                          _c("b", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "Choose Your Transaction Type and Set Date"
                                                                )
                                                              )
                                                            ),
                                                          ]),
                                                        ]),
                                                      ]),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "v-card-text",
                                                [
                                                  _c(
                                                    "v-row",
                                                    [
                                                      _c(
                                                        "v-col",
                                                        {
                                                          attrs: { cols: "12" },
                                                        },
                                                        [
                                                          _c("p", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "Select the type of transaction and specify the date for the transaction."
                                                                )
                                                              )
                                                            ),
                                                          ]),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _vm.st === 1
                                                    ? _c("TransactionConfig", {
                                                        attrs: {
                                                          transactionDate:
                                                            _vm.transactionDate,
                                                          formattedStartVariation:
                                                            _vm.formattedStartVariation,
                                                          typeTransaction:
                                                            _vm.typeTransaction,
                                                          hasAction:
                                                            _vm.hasActions,
                                                        },
                                                        on: {
                                                          "update:transactionDate":
                                                            _vm.updateStartDate,
                                                          "update:typeTransaction":
                                                            _vm.updateTransactionType,
                                                        },
                                                      })
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    {
                                      attrs: {
                                        justify: "center",
                                        align: "center",
                                      },
                                    },
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass:
                                            "d-flex justify-space-between",
                                          attrs: { cols: "2" },
                                        },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                color: "primary",
                                                disabled: !_vm.isStepOneValid,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  _vm.st = 2
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(_vm.$t("Next")) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "v-btn",
                                            {
                                              on: {
                                                click: function ($event) {
                                                  return _vm.cancelTransaction()
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(_vm.$t("Cancel")) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-stepper-content",
                                { attrs: { step: "2" } },
                                [
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c(
                                            "v-card",
                                            {
                                              attrs: {
                                                elevation: "5",
                                                outlined: "",
                                                shaped: "",
                                                tile: "",
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "card-header" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "card-title selected-item d-flex justify-space-between portfolio-card-header",
                                                    },
                                                    [
                                                      _c("div", [
                                                        _c("div", [
                                                          _c("b", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "Company Selection"
                                                                )
                                                              )
                                                            ),
                                                          ]),
                                                        ]),
                                                      ]),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "v-card-text",
                                                [
                                                  _c(
                                                    "v-row",
                                                    [
                                                      _c(
                                                        "v-col",
                                                        {
                                                          attrs: { cols: "12" },
                                                        },
                                                        [
                                                          _vm.typeTransaction ===
                                                          1
                                                            ? [
                                                                _c("p", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "Apply filters to locate the most relevant company for your needs. Choose one to advance with your transaction details."
                                                                      )
                                                                    )
                                                                  ),
                                                                ]),
                                                              ]
                                                            : [
                                                                _c("p", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "Select a company from your portfolio to proceed with the transaction details."
                                                                      )
                                                                    )
                                                                  ),
                                                                ]),
                                                              ],
                                                        ],
                                                        2
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _vm.typeTransaction === 1
                                                    ? [
                                                        _vm.st === 2
                                                          ? _c("CompanyBuy", {
                                                              on: {
                                                                "update:compoTransaction":
                                                                  _vm.updateCompositionTransaction,
                                                              },
                                                            })
                                                          : _vm._e(),
                                                      ]
                                                    : [
                                                        _vm.st === 2
                                                          ? _c("CompanySell", {
                                                              attrs: {
                                                                lastVersion:
                                                                  _vm.lastVersion,
                                                              },
                                                              on: {
                                                                "update:compoTransaction":
                                                                  _vm.updateCompositionTransaction,
                                                              },
                                                            })
                                                          : _vm._e(),
                                                      ],
                                                ],
                                                2
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    {
                                      attrs: {
                                        justify: "center",
                                        align: "center",
                                      },
                                    },
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass:
                                            "d-flex justify-space-between",
                                          attrs: { cols: "3" },
                                        },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                color: "primary",
                                                disabled:
                                                  !_vm.compositionTransaction,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  _vm.st = 3
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(_vm.$t("Next")) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "v-btn",
                                            {
                                              on: {
                                                click: function ($event) {
                                                  _vm.st = 1
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(_vm.$t("Back")) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "v-btn",
                                            {
                                              on: {
                                                click: function ($event) {
                                                  return _vm.cancelTransaction()
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(_vm.$t("Cancel")) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-stepper-content",
                                { attrs: { step: "3" } },
                                [
                                  !_vm.submitLoading
                                    ? _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12" } },
                                            [
                                              _vm.st === 3
                                                ? _c("TransactionDetails", {
                                                    attrs: {
                                                      portfolio: _vm.portfolio,
                                                      transactionDate:
                                                        _vm.transactionDate,
                                                      transactionType:
                                                        _vm.typeTransaction,
                                                      transactionCompany:
                                                        _vm.compositionTransaction,
                                                    },
                                                    on: {
                                                      "update:transactionData":
                                                        _vm.updateTransactionData,
                                                      "update:transactionValid":
                                                        _vm.updateTransactionValid,
                                                    },
                                                  })
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12" } },
                                            [
                                              _c(
                                                "v-row",
                                                {
                                                  staticClass:
                                                    "justify-content-center m-2",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "text-center m-2",
                                                    },
                                                    [
                                                      _c(
                                                        "v-progress-circular",
                                                        {
                                                          staticClass: "m-2",
                                                          attrs: {
                                                            indeterminate: "",
                                                            value: 20,
                                                            size: 70,
                                                          },
                                                        }
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                  _c(
                                    "v-row",
                                    {
                                      attrs: {
                                        justify: "center",
                                        align: "center",
                                      },
                                    },
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass:
                                            "d-flex justify-space-between",
                                          attrs: { cols: "6" },
                                        },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                color: "primary",
                                                disabled:
                                                  _vm.transactionData.length ===
                                                    0 ||
                                                  !_vm.transactionValid ||
                                                  _vm.submitLoading,
                                              },
                                              on: {
                                                click: _vm.submitTransaction,
                                              },
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  attrs: { left: "", dark: "" },
                                                },
                                                [_vm._v(" mdi-upload ")]
                                              ),
                                              _vm._v(
                                                " " +
                                                  _vm._s(_vm.$t("Submit")) +
                                                  " "
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                color: "primary",
                                                disabled:
                                                  _vm.transactionData.length ===
                                                    0 ||
                                                  !_vm.transactionValid ||
                                                  _vm.submitLoading,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.submitTransaction(
                                                    1
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  attrs: { left: "", dark: "" },
                                                },
                                                [_vm._v(" mdi-upload ")]
                                              ),
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "Submit and add another"
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-btn",
                                            {
                                              on: {
                                                click: function ($event) {
                                                  _vm.st = 2
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(_vm.$t("Back")) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "v-btn",
                                            {
                                              on: {
                                                click: function ($event) {
                                                  return _vm.cancelTransaction()
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(_vm.$t("Cancel")) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }