






import Highcharts from "highcharts";
import HighchartsMap from "highcharts/modules/map";
import { Chart } from "highcharts-vue";
import mapJson from "./mapCordinates.json"
import dataCountries from "./dataCountries.json"
import { MsiIndex } from "@/smartmsi";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

// Initialize the Highcharts map module
HighchartsMap(Highcharts);

@Component({
  components: {
    Chart, Highcharts
  },
})
export default class MapChart extends Vue {

  @Prop({ required: true }) indices!: MsiIndex[];

  get mapData() {
    // Group indices by country code and countryCode3
    const groupedByCountry: { [key: string]: { code3: string; stockExchanges: any[] } } = {};

    // Loop over each index and group them by country code
    this.indices.forEach((index) => {
      const countryCode3 = index.stockExchange.country.code3.toLowerCase();
      const countryCode = index.stockExchange.country.code.toLowerCase();

      // Initialize country data if not already present
      if (!groupedByCountry[countryCode]) {
        groupedByCountry[countryCode] = {
          code3: countryCode3,
          stockExchanges: [],
        };
      }
      // Add stockExchange to the country's list
      groupedByCountry[countryCode].stockExchanges.push(index.stockExchange);
    });

    // Update the dataCountries array with the counts and stockExchange data
    const updatedDataCountries = dataCountries.map((entry) => {
      const countryCode = entry[0];
      if (groupedByCountry[countryCode]) {
        return [
          countryCode,
          groupedByCountry[countryCode].stockExchanges.length,  // Set count as z
          groupedByCountry[countryCode].stockExchanges,  // Add stockExchange data
        ];
      } else {
        // If the country code is not in groupedByCountry, set the count to 0
        return [countryCode, 0, []];  // Empty array for stockExchanges
      }
    });

    return updatedDataCountries;
  }

  // Optionally, you can use a lifecycle hook like mounted to log the data
  mounted() {
    console.log(this.indices);
    console.log(this.mapData);

  }
  chartOptions = {
    chart: {
      map: mapJson,
      colors: ["#FFC609", "#FFDB63A1"],

    },
    title: {
      text: "",
    },
    mapNavigation: {
      enabled: true,
      enableButtons: true,
    },
    colorAxis: {
      min: 0,
      stops: [

        [0, "#f2f2f200"], // Bright yellow
        [0.5, "#FFDB63"], // Light yellow
        [1, "#FFC609"], // Semi-transparent light yellow

      ],
    },
    legend: { enabled: false },
    credits: {
      enabled: false
    },
    series: [
      {
        name: "Index",
        states: {
          hover: {
            color: null,
          },
        },
        dataLabels: {
          enabled: false,
          format: "{point.name}",
        },
        allAreas: false,
        data: this.mapData,
      },
    ],
  };
}
