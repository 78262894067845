






































































































































































































































































































































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import DatetimePicker from 'vuetify-datetime-picker';
import formatter from "@/mixins/formatter";
import Card from "@/components/Card.vue";
import UploadError from "@/components/UploadError.vue";
import VersionsPopup from '@/pages/portefeuille/VersionsPopup.vue'

Vue.use(DatetimePicker)
@Component({
    components: { Card, UploadError, VersionsPopup },
    mixins: [formatter],
})
export default class CompanyBuy extends Vue {

    countryFilterId = [];
    countries = [];
    sectorId = [];
    sectors = [];
    indexId = null;
    indices = [];
    isPanelFiltersOpen = [0, 1, 2]
    disableFilters = false
    companies = [];

    scoreRange = [0, 200]
    performanceRange = [0, 100]
    solidityRange = [0, 100]
    liquidityRange = [0, 10]
    assetRange = [0, 10]
    liabilityRange = [0, 10]
    riskRange = [0, 10]
    commercialRange = [0, 10]
    economicRange = [0, 10]
    financialRange = [0, 10]
    operatingRange = [0, 10]
    chiffreAffaireMin = null
    chiffreAffaireMax = null
    resNetMin = null
    resNetMax = null
    totalActifMin = null
    totalActifMax = null
    totalPassifMin = null
    totalPassifMax = null
    capitauxMin = null
    capitauxMax = null
    composition: any = []
    fmp = true;
    error = null;

    endpoint = "company?expand=country,legalTypology,companyActivities.activity.sector,stockExchangeComposition.stockExchangeVersion.stockExchange&page=${page}&per-page=50&sort=-score&filter[enabled][in][]=1"
    get headers() {
        return [
            // { text: "", value: "_actions" },

            { text: this.$i18n.t("models.company.name"), value: "name" },
            {
                text: this.$i18n.t("ISIN"),
                value: "profil_fmp.isin"
            },
            {
                text: this.$i18n.t("models.company.country"),
                value: "country.name",
                width: 1,
            },

            {
                text: this.$i18n.t("score"),
                filterable: false,
                value: "score",
            },
            {
                text: this.$i18n.t("performance"),  // New "Close" column
                filterable: false,
                value: "performance_score",

            },
            {
                text: this.$i18n.t("Solidité"),  // New "Nbr Action" column
                filterable: false,
                value: "solidity_score",
            },
            {
                text: this.$i18n.t("origin"),
                value: "origin",
            },
        ];
    }

    @Watch("countryFilterId")
    @Watch("sectorId")
    @Watch("indexId")
    actionCountryFilter() {
        this.disableFiltersChange()
        clearTimeout(this.filterDebounce);
        this.filterDebounce = setTimeout(() => this.loadCompanies(), 300);

    }
    @Watch("scoreRange")
    @Watch("performanceRange")
    @Watch("solidityRange")
    @Watch("indexId")
    onFilterChanged() {
        clearTimeout(this.filterDebounce);
        this.filterDebounce = setTimeout(() => this.loadCompanies(), 300);
    }

    // Watchers for filter properties
    @Watch('liquidityRange')
    @Watch('assetRange')
    @Watch('liabilityRange')
    @Watch('riskRange')
    @Watch('commercialRange')
    @Watch('economicRange')
    @Watch('financialRange')
    @Watch('operatingRange')
    onLiquidityFilterChanged() {
        clearTimeout(this.filterDebounce);
        this.filterDebounce = setTimeout(() => this.loadCompanies(), 300);
    }


    @Watch("resNetMin")
    @Watch("resNetMax")
    @Watch("totalActifMin")
    @Watch("totalActifMax")
    @Watch("totalPassifMin")
    @Watch("totalPassifMax")
    @Watch("capitauxMin")
    @Watch("capitauxMax")
    onFilterChiffreChanged() {
        if (this.chiffreAffaireMin !== null && this.chiffreAffaireMax !== null) {
            this.loadCompanies()
        }
    }
    onFilterResNetChanged() {
        if (this.resNetMin !== null && this.resNetMax !== null) {
            this.loadCompanies()
        }
    }
    onFilterTotalActiffChanged() {
        if (this.totalActifMin !== null && this.totalActifMax !== null) {
            this.loadCompanies()
        }
    }
    onFilterTotalPassifChanged() {
        if (this.totalPassifMin !== null && this.totalPassifMax !== null) {
            this.loadCompanies()
        }
    }
    onFilterCapitauxChanged() {
        if (this.capitauxMin !== null && this.capitauxMax !== null) {
            this.loadCompanies()
        }
    }

    @Watch('composition')
    onCompositionChange() {
        // alert(this.composition)
    }
    loadCompanies() {
        const filterString = this.buildFilterString();
        this.endpoint = `company?expand=country,legalTypology,companyActivities.activity.sector,stockExchangeComposition.stockExchangeVersion.stockExchange&per-page=50&filter[enabled][in][]=1${filterString}`
    }

    buildFilterString(): string {
        let filterString = '';

        if (this.countryFilterId) {
            filterString += `&filter[country.id]=${this.countryFilterId}`;
        }
        // Handle multiple selected country
        if (this.countryFilterId && this.countryFilterId.length > 0) {
            this.countryFilterId.forEach((id: number) => {
                filterString += `&filter[country.id][in][]=${id}`;
            });
        }
        // Handle multiple selected sectors
        if (this.sectorId && this.sectorId.length > 0) {
            this.sectorId.forEach((id: number) => {
                filterString += `&filter[sector.id][in][]=${id}`;
            });
        }

        if (this.indexId) {
            filterString += `&filter[stock_exchange.id][in][]=${this.indexId}`;
        }

        if (this.scoreRange && this.scoreRange.length === 2 && !(this.scoreRange[0] === 0 && this.scoreRange[1] === 200)) {
            filterString += `&filter[company.score][like]=${this.scoreRange.join(',')}`;
        }

        if (this.performanceRange && this.performanceRange.length === 2 && !(this.performanceRange[0] === 0 && this.performanceRange[1] === 100)) {
            filterString += `&filter[company.performance_score][like]=${this.performanceRange.join(',')}`;
        }

        if (this.solidityRange && this.solidityRange.length === 2 && !(this.solidityRange[0] === 0 && this.solidityRange[1] === 100)) {
            filterString += `&filter[company.solidity_score][like]=${this.solidityRange.join(',')}`;
        }
        if (this.liquidityRange && this.liquidityRange.length === 2 && !(this.liquidityRange[0] === 0 && this.liquidityRange[1] === 10)) {
            filterString += `&filter[liquidity][like]=${this.liquidityRange.join(',')}`;
        }
        if (this.assetRange && this.assetRange.length === 2 && !(this.assetRange[0] === 0 && this.assetRange[1] === 10)) {
            filterString += `&filter[actifs][like]=${this.assetRange.join(',')}`;
        }
        if (this.liabilityRange && this.liabilityRange.length === 2 && !(this.liabilityRange[0] === 0 && this.liabilityRange[1] === 10)) {
            filterString += `&filter[passifs][like]=${this.liabilityRange.join(',')}`;
        }
        if (this.riskRange && this.riskRange.length === 2 && !(this.riskRange[0] === 0 && this.riskRange[1] === 10)) {
            filterString += `&filter[risques][like]=${this.riskRange.join(',')}`;
        }
        if (this.commercialRange && this.commercialRange.length === 2 && !(this.commercialRange[0] === 0 && this.commercialRange[1] === 10)) {
            filterString += `&filter[rentabilite][like]=${this.commercialRange.join(',')}`;
        }
        if (this.economicRange && this.economicRange.length === 2 && !(this.economicRange[0] === 0 && this.economicRange[1] === 10)) {
            filterString += `&filter[rentabilite_economique][like]=${this.economicRange.join(',')}`;
        }
        if (this.financialRange && this.financialRange.length === 2 && !(this.financialRange[0] === 0 && this.financialRange[1] === 10)) {
            filterString += `&filter[rentabilite_financiere][like]=${this.financialRange.join(',')}`;
        }
        if (this.operatingRange && this.operatingRange.length === 2 && !(this.operatingRange[0] === 0 && this.operatingRange[1] === 10)) {
            filterString += `&filter[rentabilite_exploitation][like]=${this.operatingRange.join(',')}`;
        }
        if (this.chiffreAffaireMin !== null && this.chiffreAffaireMax !== null) {
            filterString += `&filter[chiffre_affaire][like]=${this.chiffreAffaireMin},${this.chiffreAffaireMax}`;
        }
        if (this.resNetMin !== null && this.resNetMax !== null) {
            filterString += `&filter[res_net][like]=${this.resNetMin},${this.resNetMax}`;
        }
        if (this.totalActifMin !== null && this.totalActifMax !== null) {
            filterString += `&filter[total_actif][like]=${this.totalActifMin},${this.totalActifMax}`;
        }
        if (this.totalPassifMin !== null && this.totalPassifMax !== null) {
            filterString += `&filter[total_passif][like]=${this.totalPassifMin},${this.totalPassifMax}`;
        }
        if (this.capitauxMin !== null && this.capitauxMax !== null) {
            filterString += `&filter[capitaux][like]=${this.capitauxMin},${this.capitauxMax}`;
        }
        return filterString;
    }
    debounce(func: Function, delay: number) {
        let timeout: number | undefined;
        return (...args: any[]) => {
            if (timeout) clearTimeout(timeout);
            timeout = setTimeout(() => func(...args), delay);
        };
    }
    disableFiltersChange() {
        console.log("this.countryFilterId", this.countryFilterId)
        if (this.countryFilterId.length === 0 && this.sectorId.length === 0 && this.indexId === null) {
            this.disableFilters = true
        } else {
            this.disableFilters = false
        }
    }

    viewCompany(id) {
        const routePath = `/company/${id}`;
        // Use window.open to open the route in a new tab
        window.open(routePath, "_blank");
        //this.$router.push(`/company/${id}`);
    }
    onSelectionChange({ selectedItems, allItems }: { selectedItems: any[]; allItems: any[] }) {
        console.log(selectedItems)
        this.$emit('update:compoTransaction', selectedItems)
    }
    created() {
        this.loadCountries()
        this.loadSectors()
        this.loadIndices()
    }
    loadCountries() {
        return this.$api.get(`country`).then(res => {
            this.countries = res.data;
            // console.log("countrys",res.data)
        });
    }
    loadSectors() {
        return this.$api.get(`sector`).then(res => {
            this.sectors = res.data;
            // console.log("countrys",res.data)
        });
    }
    loadIndices(countryId?: number | null) {
        return this.$api.get(`stock-exchange?filter[stockExchange.active][like]=1`).then(res => {
            this.indices = res.data;
        });
        // }
    }
    onChangeCountry(selectedItem: any) {
        this.countryFilterId = selectedItem;
    }
    onChangeSector(selectedItem: any) {
        this.sectorId = selectedItem;
    }
    setConforme(filter: string) {
        switch (filter) {
            case "score":
                this.scoreRange = [100, 200];
                break
            case "performance":
                this.performanceRange = [50, 100]
                break
            case "solidity":
                this.solidityRange = [50, 100]
                break
            case "liquidity":
                this.liquidityRange = [5, 10]
                break
            case "asset":
                this.assetRange = [5, 10]
                break
            case "liability":
                this.liabilityRange = [5, 10]
                break
            case "risk":
                this.riskRange = [5, 10]
                break
            case "commercial":
                this.commercialRange = [5, 10]
                break
            case "economic":
                this.economicRange = [5, 10]
                break
            case "financial":
                this.financialRange = [5, 10]
                break
            case "operating":
                this.operatingRange = [5, 10]
                break
        }
    }

    setNonConforme(filter: string) {
        switch (filter) {
            case "score":
                this.scoreRange = [0, 99];
                break
            case "performance":
                this.performanceRange = [0, 49]
                break
            case "solidity":
                this.solidityRange = [0, 49]
                break
            case "liquidity":
                this.liquidityRange = [0, 5]
                break
            case "asset":
                this.assetRange = [0, 5]
                break
            case "liability":
                this.liabilityRange = [0, 5]
                break
            case "risk":
                this.riskRange = [0, 5]
                break
            case "commercial":
                this.commercialRange = [0, 5]
                break
            case "economic":
                this.economicRange = [0, 5]
                break
            case "financial":
                this.financialRange = [0, 5]
                break
            case "operating":
                this.operatingRange = [0, 5]
                break
        }
    }

    restrictInput(event, attributeName: string) {
        // Allow: backspace, delete, tab, escape, enter
        const allowedKeys = [
            'Backspace',
            'Delete',
            'Tab',
            'Escape',
            'Enter',

        ];
        const currentAttribute = this[attributeName];
        // Check for allowed keys
        if (allowedKeys.includes(event.key) || (event.ctrlKey && allowedKeys.includes(event.key))) {
            return; // Allow these keys
        }
        // Allow only digits and a decimal point
        const isDigit = event.key >= '0' && event.key <= '9';
        const isDecimalPoint = event.key === '.';

        // Prevent the first character from being anything other than a digit (not including decimal point)
        if (currentAttribute && currentAttribute.length === 0 && !isDigit && isDecimalPoint) {
            event.preventDefault(); // Block if the first character is not a digit
            return;
        }
        if (!currentAttribute && isDecimalPoint) {
            event.preventDefault(); // Block if the first character is not a digit
            return;
        }
        // Prevent multiple decimal points
        if (isDecimalPoint && currentAttribute.includes('.')) {
            event.preventDefault(); // Block if another decimal point is already present
            return;
        }

        // Block any invalid keys (anything other than digits and a decimal point)
        if (!isDigit && !isDecimalPoint) {
            event.preventDefault(); // Block invalid characters
        }
    }
}
