<template>
  <div>
    <v-row>
      <v-col md="5">
        <v-card>
          <v-card-title>{{ $t('stock.title') }}</v-card-title>
          <v-btn right @click="addDialog = true" class="add-index-btn">Ajouter</v-btn>
          <v-card-text>
            <!-- <v-list v-if="indices.length" subheader two-line>
              <index-list-item :index="index" v-for="index in indices" :key="`index-${index.id}`"/>
            </v-list> -->
            <grid-view ref="grid" :headers="headers" endpoint="msi-index?expand=stockExchange.country"
              model="StockExchange" :loading="loading">

              <template v-slot:item.stockExchange.name="{ item }">
                <CountryFlag :code="item.stockExchange.country.code" v-if="item.stockExchange.country" />
                {{ (item.stockExchange.name) }}
                <!-- <sub>{{ item.stockExchange.isin }}</sub> -->
              </template>
              <template v-slot:item.stockExchange.country.name="{ item }">
                {{ translate(item.stockExchange.country.name) }}
              </template>
              <template v-slot:item.stockExchange.last.now="{ item }">
                {{ (item.stockExchange.last.now) }}
              </template>

              <template v-slot:item._actions="{ item }">
                <v-btn @click="viewIndex(item)" icon small>
                  <v-icon small>mdi-eye</v-icon>
                </v-btn>
                <v-btn @click="activateIndex(item)" icon small>
                  <v-icon v-if="item.stockExchange.active" small>fa fa-unlock-alt</v-icon>
                  <v-icon v-else small>fa fa-lock</v-icon> <!-- Remove the condition here -->
                </v-btn>
                <v-btn @click="viewSetting(item, item.stockExchange.id, item.stockExchange.name)" icon small>
                  <v-icon small>fa fa-cogs</v-icon>
                </v-btn>

                <v-tooltip bottom color="black" class="text-center">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" @click="changeMode(item)" icon small>
                      <v-icon v-if="item.stockExchange.mode" small class="auto-mode">fa fa-sync</v-icon>
                      <v-icon v-else small class="manual-mode">fa fa-hand-paper</v-icon>
                      <!-- Remove the condition here --> </v-btn>
                  </template>
                  <div v-if="item.stockExchange.mode" class="text-center">
                    <span>{{ $t("This index is in automatic mode.") }}</span><br>
                    <span>{{ $t("Switching to manual mode will disable automatic updates and require manual intervention for changes.")}}</span>
                  </div>
                  <div v-else class="text-center">
                    <span>{{ $t("This index is in manual mode.") }}</span><br>
                    <span>{{ $t("Switching to automatic mode will streamline updates and ensure smart versions are generated periodically.") }}</span>
                  </div>
                </v-tooltip>
              </template>
            </grid-view>
          </v-card-text>
          <!-- <v-card-actions>
            <v-btn right @click="addDialog = true">Ajouter</v-btn>
          </v-card-actions> -->
        </v-card>
      </v-col>
      <v-col md="7">
        <v-card>
          <v-card-title>World Map</v-card-title>
          <v-card-text>
            <Map v-if="!loading" @seiClick="viewIndex" :indices="indices" />
          </v-card-text>
        </v-card>
      </v-col>

    </v-row>

    <!-- edit dialog -->
    <v-dialog v-model="addDialog" max-width="600">
      <msi-index-form @done="onIndexAdded" />
    </v-dialog>

  </div>
</template>

<script>
import { Component, Vue } from "vue-property-decorator";
import formatter from "@/mixins/formatter";
import World from "@/components/charts/World";
import Map from "@/components/charts/Map";
import CountryFlag from "@/components/CountryFlag";
import MsiIndexForm from "@/components/stock/MsiIndexForm";
import IndexListItem from "@/components/stock/IndexListItem";
import GridView from "@/components/GridView";

@Component({ components: { IndexListItem, GridView, CountryFlag, MsiIndexForm, World, Map }, mixins: [formatter] })
export default class IndexIndex extends Vue {

  indices = [];
  addDialog = false;
  loading = true;

  created() {
    this.getIndices();
  }

  get headers() {
    return [
      { text: '', value: '_actions', },
      { text: this.$i18n.t('name'), value: 'stockExchange.name', },
      {
        text: this.$i18n.t("models.country.self"),
        value: "stockExchange.country.name",
        width: 1,
      }, { text: this.$i18n.t('last'), value: 'stockExchange.last.now', },
    ];
  }
  onIndexAdded() {
    this.addDialog = false;
    this.getIndices();
  }

  getIndices() {
    this.$api.get(`msi-index?expand=stockExchange.country&filter[stockExchange.active][like]=1`).then(res => {
      this.indices = res.data;
      this.loading = false;
    });
  }

  viewSetting(index, stock, name) {
    // Encode the name parameter
    const encodedName = encodeURIComponent(name);
    this.$router.push(`/stock/${index.id}/setting/${stock}/${encodedName}`);
  }

  viewIndex(index) {
    this.$router.push(`/stock/${index.id}`);
  }

  activateIndex(index) {
    let active = 'désactiver'; let activeVal = 1;
    if (index.stockExchange.active == 1) {
      activeVal = 0;
      active = 'désactiver';
    } else {
      activeVal = 1;
      active = 'activer';
    }

    this.$root.$confirm
      .open(
        "Activation index",
        "Êtes-vous sûr(e) de vouloir " + active + " cet index ?",
        {}
      )
      .then(confirm => {
        if (confirm) {
          this.$api.post(`stock-exchange/active-stock?stock=${index.stockExchange.id}&active=${activeVal}`).
            then(response => {
              this.$refs.grid.getModels();
            }).catch((e) => {
              this.error = e.response.data;
            }).finally(() => {

            });
        }
      });
  }


  changeMode(index) {
    let mode = 'automatique';
    let modeVal = 1;
    if (index.stockExchange.mode == 1) {
      modeVal = 0;
      mode = 'manuelle';
    } else {
      modeVal = 1;
      mode = 'automatique';
    }

    this.$root.$confirm
      .open(
        "Change mode",
        "Êtes-vous sûr(e) de vouloir changé le mode à " + mode + " pour cet index ?",
        {}
      )
      .then(confirm => {
        if (confirm) {
          this.$api.post(`stock-exchange/change-mode?stock=${index.stockExchange.id}&mode=${modeVal}`).
            then(response => {
              this.$refs.grid.getModels();
            }).catch((e) => {
              this.error = e.response.data;
            }).finally(() => {

            });
        }
      });
  }
}

</script>
<style lang="scss" scoped>
.add-index-btn {
  position: absolute;
  top: 15px;
  right: 15px;
}


.auto-mode {
  color: #a1fda1 !important;
}

.manual-mode {
  color: #ff8b8b !important;
}


.filter-icon {
  margin-right: 10px;
  /* Add spacing between icons */
  cursor: pointer;
  vertical-align: middle;
  /* Vertically center the icons */
}
</style>
