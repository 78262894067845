var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c("v-data-table", {
                staticClass: "elevation-1",
                attrs: {
                  headers: _vm.headers,
                  items: _vm.companies,
                  "single-select": _vm.singleSelect,
                  "item-key": "name",
                  "show-select": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "item.name",
                    fn: function (ref) {
                      var item = ref.item
                      return [
                        _vm._v(" " + _vm._s(item.name) + " "),
                        item.symbol
                          ? _c("span", [
                              _vm._v(" - " + _vm._s(item.symbol) + " "),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                  {
                    key: "item.country.name",
                    fn: function (ref) {
                      var item = ref.item
                      return [
                        _vm._v(
                          " " + _vm._s(_vm.translate(item.country.name)) + " "
                        ),
                      ]
                    },
                  },
                  {
                    key: "item.web",
                    fn: function (ref) {
                      var item = ref.item
                      return [
                        item.web
                          ? _c(
                              "v-icon",
                              { attrs: { small: "", color: "green" } },
                              [_vm._v("fa fa-check")]
                            )
                          : _vm._e(),
                        !item.web
                          ? _c(
                              "v-icon",
                              { attrs: { small: "", color: "red" } },
                              [_vm._v("fa fa-times")]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                  {
                    key: "item._actions",
                    fn: function (ref) {
                      var item = ref.item
                      return [
                        _c(
                          "v-btn",
                          {
                            attrs: { icon: "", small: "" },
                            on: {
                              click: function ($event) {
                                return _vm.viewCompany(item)
                              },
                            },
                          },
                          [
                            _c("v-icon", { attrs: { small: "" } }, [
                              _vm._v("mdi-eye"),
                            ]),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
                model: {
                  value: _vm.company,
                  callback: function ($$v) {
                    _vm.company = $$v
                  },
                  expression: "company",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }