
























































































































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { setPageTitle } from "@/utils/meta";
import Card from "@/components/Card.vue";
import formatter from "@/mixins/formatter";
import PortfolioView from "./View.vue";
import DatetimePicker from 'vuetify-datetime-picker';
import StepTwo from "./steps/stepTwo.vue";
import StepSell from "./steps/stepSell.vue";
import TransactionConfig from "./steps/transactionConfig.vue";
import CompanyBuy from "./steps/companyBuy.vue";
import CompanySell from "./steps/companySell.vue";
import TransactionDetails from "./steps/transactionDetails.vue";
Vue.use(DatetimePicker)
@Component({
    components: { Card, TransactionConfig, CompanyBuy, CompanySell, TransactionDetails },
    mixins: [formatter],
})
export default class AddTransaction extends Vue {
    @Prop() portfolioId!: number;
    @Prop() portfolioName!: string;
    @Prop() portfolioDevise!: string;

    st = 1
    transactionDate = null
    formattedStartVariation = null
    typeTransaction = 1
    compositionTransaction = null
    portfolio = null
    portfolioCurrency = null
    portfolioBudget = null
    lastVersion = null
    hasActions = false
    transactionData = []
    transactionValid = false
    submitLoading = false
    snackBarSuccess = true
    snackBarMessage = this.$t("Transaction successful!")
    // Handle the start_date update from StepOne
    updateStartDate(newStartDate: string) {
        this.transactionDate = newStartDate;
    }

    created() {
        console.log("compo", this.compositionTransaction)
        this.loadPortfolioInfo()
    }
    // Handle the typeTransaction update from StepOne
    updateTransactionType(newTypeTransaction: number) {
        this.typeTransaction = newTypeTransaction;
    }
    get isStepOneValid() {
        return this.transactionDate !== null && this.typeTransaction !== null;
    }
    cancelTransaction() {
        this.$router.push(`/portfolio/${this.portfolioId}/setting/${this.portfolioName}/${this.portfolioDevise}`);
    }

    loadPortfolioInfo() {
        return this.$api
            .get(
                `portfolio/${this.portfolioId}?expand=portfolioVersions.portfolioComposition.company.country`
            )
            .then(res => {
                this.portfolio = res.data
                console.log("this.portfolio", this.portfolio)
                const dateStartPortfolio = res.data.start_date_variation;
                // console.log("datestart", dateStartPortfolio)
                this.portfolioCurrency = res.data.devise
                this.portfolioBudget = parseFloat(res.data.budget).toFixed(3)
                if (
                    res.data.portfolioVersions &&
                    res.data.portfolioVersions.length > 0
                ) {
                    const lastPortfolioVersion = res.data.portfolioVersions
                        .slice()
                        .sort((a, b) => new Date(a.date_version) - new Date(b.date_version))
                        .pop();
                    console.log("lastPortfolioVersion", lastPortfolioVersion)
                    this.lastVersion = lastPortfolioVersion;
                    this.formattedStartVariation = this.formatDateTime(lastPortfolioVersion.date_version)
                    this.hasActions = true
                } else {
                    this.formattedStartVariation = this.formatDateTime(res.data.start_date_variation)

                }
            });
    }

    updateCompositionTransaction(compo: any) {
        this.compositionTransaction = compo;
        // alert(compo)
        console.log("compo", compo)
    }
    updateTransactionData(transaction: any) {
        this.transactionData = transaction;
        console.log("transactionData", this.transactionData)

    }
    updateTransactionValid(transactionValid: boolean) {
        this.transactionValid = transactionValid;
        console.log("transactionValid", this.transactionValid)

    }
    submitTransaction(redirect = 0) {

        this.submitLoading = true;
        const formattedStartVariation = this.formatDateTime(this.transactionDate)
        let url = `portfolio/integrate-version?id=${this.portfolioId}`;
        this.$api
            .post(url, {
                composition: this.transactionData,
                dateDebut: formattedStartVariation
            })
            .then(response => {
                this.submitLoading = false;
                // this.snackBarSuccess = true
                this.$snack.success("Transaction successful!", false, true, true);

                console.log("response", response)
                if (redirect === 1) {
                    this.st = 1;
                    this.$router.push(`/portfolio/${this.portfolioId}/add-transaction/${this.portfolioName}/${this.portfolioDevise}`);
                } else {
                    this.$router.push(`/portfolio/${this.portfolioId}/setting/${this.portfolioName}/${this.portfolioDevise}`);
                }
            })
            .catch(e => {
                this.submitLoading = false;
            })
            .finally(() => {
            });
    }
    formatDateTime(dateString: any) {
        const date = new Date(dateString); // Parse the input date string

        // Extract date parts
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed, so add 1
        const day = String(date.getDate()).padStart(2, '0');

        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');

        // Return in the desired format
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    }
}

