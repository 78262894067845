












































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import formatter from "@/mixins/formatter";
import Card from "@/components/Card.vue";
import UploadError from "@/components/UploadError.vue";

@Component({
    components: { Card, UploadError },
    mixins: [formatter],
})
export default class TransactionDetails extends Vue {
    @Prop() portfolio!: any;
    @Prop() transactionDate!: number;
    @Prop() transactionType!: number;
    @Prop() transactionCompany!: number;
    transactions: any[] = [];

    mounted() {
        // Create a copy of the prop to modify locally
        if (Array.isArray(this.transactionCompany)) {
            this.transactions = [...this.transactionCompany];
        } else {
            this.transactions = [];
        }

        this.getCompaniesInfo()
    }
    currentBudget = null
    formattedVariationDate = ""
    transactionTypeArray = [
        { "id": 1, "color": "green", "description": "Purchase stocks", "icon": "mdi-arrow-up-thin-circle-outline" },
        { "id": 0, "color": "red", "description": "Sell stocks", "icon": "mdi-arrow-down-thin-circle-outline" }
    ]
    portfolioCurrency = null
    loading = true;
    companyClose = 0
    companyScore = null
    companySolidity = null
    companyPerformance = null
    transactionPrice = 0
    transactionFees = 0
    priceLabel = "Purchase price"
    transactionNbrAction = 0
    transactionTotal = 0
    transactionData: any = []
    transactionValid = false
    errorValidation = false
    alertDismiss = true
    errorMessage = ""

    created() {
        console.log("portfolio", this.portfolio)
        console.log("transactionDate", this.transactionDate)
        console.log("transactionType", this.transactionType)
        console.log("transactionCompany", this.transactionCompany)
        this.initializeData()

    }

    initializeData() {
        if (this.portfolio.portfolioVersions.length > 0) {
            const lastPortfolioVersion = this.portfolio.portfolioVersions
                .slice()
                .sort((a, b) => new Date(a.date_version) - new Date(b.date_version))
                .pop();
            this.currentBudget = parseFloat(lastPortfolioVersion.cash_balance).toFixed(3)

        } else {
            this.currentBudget = parseFloat(this.portfolio.budget).toFixed(3)
        }
        this.formattedVariationDate = this.formatDateTime(this.transactionDate)
        this.portfolioCurrency = this.portfolio.devise
        if (this.transactionType === 0) {
            this.priceLabel = "Sale price"
        } else {
            this.priceLabel = "Purchase price"
        }
    }


    getCompaniesInfo() {
        const promises = this.transactions.map(company => {
            return this.$api
                .get(
                    `portfolio/company-info?id=${company.id}&devise=${company.profil_fmp.currency}&portfolioDevise=${this.portfolioCurrency}&date=${this.formattedVariationDate}`
                )
                .then(res => {
                    company.close = res.data?.close;
                    company.score = res.data.score?.global;
                    company.solidity = res.data.score?.A1;
                    company.performance = res.data.score?.A2;
                    company.nbrAction = 0;
                    company.fees = 0;
                    company.total = 0;
                    company.price = 0;
                    company.dateStart = this.formattedVariationDate
                    company.devise = company?.profil_fmp.currency
                    company.type = this.transactionType
                });
        });

        Promise.all(promises)
            .then(() => {
                this.loading = false;
            })
            .catch(error => {
                console.error("Error fetching company info:", error);
                this.loading = false; // Ensure loading is set to false even if an error occurs
            });
    }


    formatDateTime(dateString: any) {
        const date = new Date(dateString); // Parse the input date string
        // Extract date parts
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed, so add 1
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');
        // Return in the desired format
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    }

    get selectedTransactionType() {
        return this.transactionTypeArray.find(item => item.id === this.transactionType) || {};
    }

    getCurrencySymbol(currency: any) {
        if (currency != null) {
            const currencySymbols = {
                'EUR': '€',
                'AUD': 'A$', // Australian Dollar symbol
                'TRY': '₺',
                'SEK': 'kr',
                'INR': '₹',
                'JPY': '¥',
                'KRW': '₩',
                'USD': '$',
                'NOK': 'kr',
                'CNY': '¥',
                'CAD': 'CA$',
                'THB': '฿',
                'GBP': '£',
                'HKD': 'HK$',
                'TWD': 'NT$',
                'ZAR': 'R',
                'IDR': 'Rp',
                'BRL': 'R$',
                'PLN': 'zł',
                'SGD': 'S$',
                'ILS': '₪',
                'QAR': '﷼',
                'SAR': '﷼',
                'DKK': 'kr',
                'CLP': 'CLP$',
                'MXN': 'MX$',
                'CHF': 'CHF',
                'NZD': 'NZ$',
                'MYR': 'RM',
                'ISK': 'kr',
                'ARS': '$',
                'CZK': 'Kč',
                'HUF': 'Ft',
                'AED': 'د.إ',
                'KWD': 'د.ك',
                'TND': 'د.ت'
            };
            return currencySymbols[currency] || currency; // Fallback to currency code if symbol is not found
        } else {
            return '€'
        }
    }

    updatePrice(index: any) {
        const item = this.transactions[index]
        console.log("item", item)
        let action = Number(item.nbrAction);
        let fees = Number(item.fees);
        let price = Number(item.price);
        let valo = 0;
        if (this.transactionType === 1) {
            valo = (action * price) + fees
        } else {
            valo = (action * price) - fees
        }
        // const formattedTransactionDate = this.formatDateTime(this.transactionDate)
        // this.$emit('update:transactionValid', false)
        if (valo > 0) {
            this.transactions[index].total = valo
            this.$set(this.transactions, index, {
                ...this.transactions[index],
                total: valo,
            });
            console.log("this.transactions[index].total", this.transactions[index].total)
            // this.transactions[transactionIndex].score = company.score;
            // this.transactions[transactionIndex].solidity = company.solidity;
            // this.transactions[transactionIndex].performance = company.performance;
            // this.transactions[transactionIndex].total = valo;
            // this.transactions[transactionIndex].dateStart = formattedTransactionDate
            // this.transactions[transactionIndex].devise = company?.profil_fmp.currency
            // this.transactions[transactionIndex].fees = company.fees
            // this.transactions[transactionIndex].global = company.score
            // this.transactions[transactionIndex].nbrAction = company.nbrAction
            // this.transactions[transactionIndex].percentage = 100
            // this.transactions[transactionIndex].performance = company.performance
            // this.transactions[transactionIndex].price = company.price
            // this.transactions[transactionIndex].solidity = company.solidite
            // this.transactions[transactionIndex].type = this.transactionType
            // this.transactions[transactionIndex].close = company.close

            console.log("transactions", this.transactions)
        }
        this.$emit('update:transactionData', this.transactions)
        this.validateCompany()
    }
    activateSubmitButton() {

    }
    validateCompany() {
        let validTotal = true
        this.transactions.map(item => {
            if (item.total == 0) {
                validTotal = false
            }
        })
        if (validTotal == true) {
            const url = `portfolio/validate-portfolio-transaction?id=${this.portfolio.id}`
            this.alertDismiss = true
            this.transactionValid = false
            this.errorValidation = false
            this.errorMessage = ""
            this.$api
                .post(url, {
                    composition: this.transactions,
                    type: this.transactionType,
                }).then(res => {
                    console.log("res", res)
                    this.errorValidation = false
                    this.errorMessage = ""
                    this.$emit('update:transactionValid', true)
                }).catch(error => {
                    console.log("error", error.response)
                    this.errorValidation = true
                    this.errorMessage = error.response.data.message
                    this.transactionValid = false
                    this.$emit('update:transactionValid', false)
                    setTimeout(() => {
                        this.alertDismiss = false
                    }, 4000)
                    return

                });
        }

    }
}
