






import Component from "vue-class-component";
import Vue from "vue";
import * as Highcharts from "highcharts";
import HCData from "highcharts/modules/data";
import chartExport from "highcharts/modules/exporting";
import dataExport from "highcharts/modules/export-data";
import stockInit from "highcharts/modules/stock";
import indicators from "highcharts/indicators/indicators-all";
import { Prop } from "vue-property-decorator";
import { Chart } from "highcharts-vue";
import { Portfolio } from "@/smartmsi";
import { startOfYear, subMonths } from "date-fns";

dataExport(Highcharts);
chartExport(Highcharts);
stockInit(Highcharts);
indicators(Highcharts);
HCData(Highcharts);

@Component({ components: { Chart } })
export default class PortfolioChart extends Vue {
  @Prop({ required: true }) portfolio!: Portfolio;
  @Prop({ required: false, default: false }) pollingEnabled!: boolean;

  pollingRate = 2;

  chartData: any = null;

  get chartOptions() {
    return {
      chart: {
        type: "spline",
        height: 800,
      },
      title: {
        text: this.portfolio?.name,
      },
      subtitle: {
        text: this.portfolio?.devise,
      },
      credits: {
        enabled: false,
      },
      yAxis: {
        opposite: false,
      },
      series: this.chartData?.series || [],

    };
  }

  async mounted() {
    await this.fetchChartData();
  }
  parseCSV(csvData: string) {
    const rows = csvData.split("\n").filter(row => row.trim()); // Remove empty rows
    return rows.slice(1).map(row => {
      const values = row.split(",").map(v => v.trim().replace(/"/g, "")); // Clean quotes
      return [
        new Date(values[0]).getTime(), // Convert timestamp to UNIX time
        parseFloat(values[1]) || 0, // Parse first numeric value
        parseFloat(values[2]) || 0, // Parse second numeric value if exists
      ];
    });
  }
  async fetchChartData() {
    try {
      const response = await this.$api.get(
        `/portfolio/csv/${this.portfolio.id}?polling=${this.pollingEnabled ? 1 : 0}`
      );
      const parsedData = this.parseCSV(response.data);

      this.chartData = {
        series: [
          {
            name: "Series 1",
            data: parsedData.map(row => [new Date(row[0]).getTime(), row[1]]), // Timestamp and value
          }
        ],
      };
      this.broadcastData(response.data);
    } catch (error) {
      console.error("Failed to fetch chart data", error);
    }
  }

  broadcastData(rawData: string) {
    // Parse the CSV data
    const rows = rawData.split("\n").map((row) => row.split(","));
    // Extract headers and process the data
    const headers = rows[0]; // First row contains headers
    const data = rows.slice(1).map((row) => [
      new Date(row[0].replace(/"/g, "")).getTime(), // Remove quotes and convert to timestamp
      parseFloat(row[1]), // Convert value to a number
    ]);
    const _3M = subMonths(new Date(), 3).getTime();
    const _6M = subMonths(new Date(), 6).getTime();
    const YTD = subMonths(new Date(), 12).getTime();
    const JAN = startOfYear(new Date()).getTime();
    const msiValues = {
      "3M": 0,
      "6M": 0,
      JAN: 0,
      YTD: 0,
      ALL: data[0][1], // Use the first value in the processed data
    };

    data.forEach(([timestamp, value]) => {
      if (!msiValues["JAN"] && JAN < timestamp) {
        msiValues["JAN"] = value;
      } else if (!msiValues["3M"] && _3M < timestamp) {
        msiValues["3M"] = value;
      } else if (!msiValues["6M"] && _6M < timestamp) {
        msiValues["6M"] = value;
      } else if (!msiValues["YTD"] && YTD < timestamp) {
        msiValues["YTD"] = value;
      }
    });
    const lastValue = data[data.length - 2][1];
    this.$emit("setData", {
      samples: [msiValues],
      last: [data[data.length - 2][1]], // Last value from the processed data
    });
  }

}
