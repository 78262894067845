

































































































































































































































































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import UploadError from "@/components/UploadError.vue";
import { setPageTitle } from "@/utils/meta";
import Card from "@/components/Card.vue";
import formatter from "@/mixins/formatter";
import importVersion from "@/pages/stock/Import.vue"
import { ExportStockVersions } from "@/services/xlsx.ts";
import DatetimePicker from 'vuetify-datetime-picker';

Vue.use(DatetimePicker)
@Component({
    components: { Card, UploadError, importVersion },
    mixins: [formatter]
})
export default class StockSetting extends Vue {

    @Prop() indexId!: number;
    @Prop() stockId!: number;
    @Prop() stockName!: string;
    error = null;
    uploadingData = false;
    doneUpload = false;
    companyId = 0;
    countryId = null;
    seuil = null;
    startVariation = null; //new Date();
    menu = false;
    // companies = [];
    selectedCompanies = [];
    countries = [];
    loading = true;
    exist = false;
    update = false;
    importDialog = false;
    model = null;
    isPanelOpen = [0, 1]
    indexVersions = []
    selectedIndexVersion = []
    selectedVersionId = null
    tabl = 0;
    tabl2 = 0;
    msiVersions = []
    hasMsiVersions = false
    success = false
    successMessage = null
    selectedVersionDate = new Date()
    nextVersionDate = new Date()
    loadedCompanies = [];
    selectedItemVersion = null
    selectedStockExchangeVersion = null
    selectedStockExchangeComposition = null

    loadingStockVersions = false
    @Watch('selectedIndexVersion')
    changeSelectedVersionDate() {
        const selectedIndex = this.indexVersions.findIndex(item => item.id === this.selectedIndexVersion);

        if (selectedIndex !== -1) {
            const selectedItem = this.indexVersions[selectedIndex];

            // Handle the selected item's date for `selectedVersionDate` (min date)
            if (selectedItem?.date_version) {
                const selectedDate = new Date(selectedItem.date_version);
                const timezoneOffset = selectedDate.getTimezoneOffset() * 60000; // offset in milliseconds
                const localSelectedDate = new Date(selectedDate.getTime() - timezoneOffset);
                this.selectedVersionDate = localSelectedDate.toISOString().slice(0, 16);
                console.log(localSelectedDate.toISOString().slice(0, 16))  // yyyy-MM-ddTHH:mm
                this.startVariation = selectedDate;
            } else {
                this.selectedVersionDate = null;
            }

            // Handle the next item's date for `nextVersionDate` (max date)
            const nextItem = this.indexVersions[selectedIndex + 1]; // Get the next item
            if (nextItem?.date_version) {
                const nextDate = new Date(nextItem.date_version);
                const timezoneOffset = nextDate.getTimezoneOffset() * 60000; // offset in milliseconds
                const localNextDate = new Date(nextDate.getTime() - timezoneOffset);

                // Subtract one day from the local date
                localNextDate.setDate(localNextDate.getDate() - 1);

                // Format and set the max date
                this.nextVersionDate = localNextDate.toISOString().slice(0, 16); // yyyy-MM-ddTHH:mm

            } else {
                this.nextVersionDate = null;
            }
        } else {
            // Fallback if no valid index is found
            this.selectedVersionDate = null;
            this.nextVersionDate = null;
        }
    }
    created() {
        setPageTitle("Stock Setting ");

        this.loadMsiComposition();
        // this.loadCompanies();
        this.loadIndexVersion()
        this.$api.get("country?per-page=0").then(j => (this.countries = j.data));
    }
    // loadCompanies() {
    //     return this.$api.get(`company/list?stock=${this.stockId}`).then(res => {
    //         this.companies = res.data;
    //         this.loading = false;
    //     });
    // }
    configImported() {
        this.importDialog = false;
        // this.$refs.grid.getModels();
        this.loadIndexComposition();
    }
    getIcon(status: 'unprocessed' | 'pending' | 'running' | 'finished' | 'failed') {
        const icons = {
            unprocessed: "mdi-information",
            pending: "mdi-clock-outline",
            running: "mdi-progress-clock",
            finished: "mdi-check-circle-outline",
            failed: "mdi-alert-circle-outline",
        };
        return icons[status]; // Default icon for unknown status
    }
    getIconText(status: 'unprocessed' | 'pending' | 'running' | 'finished' | 'failed') {
        const icons = {
            unprocessed: "This version has not been processed and does not have a task in the queue.", // New tooltip
            pending: "This version is in the queue and awaiting processing.",
            running: "This version is currently being calculated. Please wait until the process is complete.",
            finished: "Processing of this version is complete and it is ready for use.",
            failed: "The processing of this version encountered an error and could not be completed.",
        };
        return icons[status]; // Default icon for unknown status
    }
    getBgIcon(status: 'pending' | 'running' | 'finished' | 'failed') {
        const icons = {
            unprocessed: "#cdcdcd",
            pending: "#fff450",
            running: "#ffaf35",
            finished: "#6fff35",
            failed: "#ff7250",
        };
        return icons[status]; // Default icon for unknown status
    }
    loadMsiComposition() {
        return this.$api.get(`stock-exchange/list-composition?stock=${this.stockId}`).then(res => {
            this.msiVersions = res.data.msiVersion
            // this.seuil = res.data.seuil;
            if (res.data.msiVersion.length > 0) {
                const lastVersionIndex = this.msiVersions.length - 1;
                this.tabl2 = lastVersionIndex !== -1 ? lastVersionIndex : 0;
                this.countryId = res.data.country_id;
                if (this.selectedCompanies.length > 0) {
                    this.exist = true;
                }
                this.hasMsiVersions = true
            }
            //this.loadCompanies();
        });
    }

    processVersions() {

        this.$api.get(`stock-exchange/launch-msi-index-version?id=${this.stockId}`).then(res => {
            console.log("res.data", res.data)
            this.successMessage = res.data.message
            this.success = true
            setTimeout(() => {
                this.success = false;
                this.successMessage = null;
                window.location.reload(); // Reload the window

            }, 3000);
        }).catch(e => {

        });

    }
    loadIndexVersion() {
        this.loadingStockVersions = true
        return this.$api.get(`stock-exchange/${this.stockId}?expand=stockExchangeVersions`).then(res => {
            this.indexVersions = res.data?.stockExchangeVersions || []

            const lastVersionIndex = this.indexVersions.length - 1;
            this.selectedStockExchangeVersion = this.indexVersions[lastVersionIndex]
            // console.log(lastVersion)
            this.tabl = lastVersionIndex !== -1 ? lastVersionIndex : 0;
            this.loadVersionComposition(this.selectedStockExchangeVersion.id);
        });
    }
    loadVersionComposition(id: any) {
        this.loadingStockVersions = true
        return this.$api.get(`stock-exchange-composition?expand=company&filter[StockExchangeVersion.stock_exchange_version_id]=${id}`).then(res => {
            console.log("data", res.data)
            this.selectedStockExchangeComposition = res.data
            this.loadingStockVersions = false
        });
    }
    getPreviousValidScore(startVariationDate: any, scores: any) {
        let year = startVariationDate - 1; // Start from the year before the given date
        const minYear = Math.min(...Object.keys(scores).map(Number)); // Find the earliest year in scores

        while (year >= minYear) {
            if (scores[year]) {
                const score = scores[year].global; // Access the 'global' score
                if (score !== 0 && score !== null) {
                    return score; // Return the valid score
                }
            }
            year--; // Move to the previous year
        }
        return null; // Return null if no valid score is found
    }
    getNextValidScore(startVariationDate: any, scores: any) {
        let year = startVariationDate + 1; // Start from the year after the given date
        const maxYear = Math.max(...Object.keys(scores).map(Number)); // Find the latest year in scores

        while (year <= maxYear) {
            if (scores[year]) {
                const score = scores[year].global; // Access the 'global' score
                if (score !== 0 && score !== null) {
                    return score; // Return the valid score
                }
            }
            year++; // Move to the next year
        }
        return null; // Return null if no valid score is found
    }
    formatDate(dateString) {
        const date = new Date(dateString);
        return date.toLocaleDateString('en-CA'); // This will format the date as YYYY-MM-DD
    }

    getScore(companyYear, scores) {
        let score = 0
        score = this.getPreviousValidScore(companyYear, scores);

        // If no valid score was found, get the next valid score
        if (score === null) {
            score = this.getNextValidScore(companyYear, scores);
        }
        if (score === null) {
            return 'N/A'
        }
        return score
    }
    async loadCompanies() {
        const versionId = this.selectedIndexVersion; // Get the selected version ID
        if (versionId) {
            const selectedItem = this.indexVersions.find(item => item.id === versionId);
            if (selectedItem) {
                this.selectedItemVersion = selectedItem; // Assign the selected item
                let stockExchangeComposition = null
                await this.$api.get(`stock-exchange-composition?expand=company&filter[StockExchangeVersion.stock_exchange_version_id]=${selectedItem.id}`).then(res => {
                    stockExchangeComposition = res.data
                });
                if (stockExchangeComposition && stockExchangeComposition.length > 0) {
                    // Process or display the stockExchangeComposition
                    console.log("Stock Exchange Composition:", stockExchangeComposition);
                    const startVariationDate = selectedItem.start_variation;

                    // Map over the stockExchangeComposition to add a valid score to each company
                    let companiesWithScores = stockExchangeComposition.map(item => {
                        const company = item.company;

                        // Get the year of the company's start_variation date
                        const companyStartVariationDate = new Date(this.startVariation);
                        const companyYear = companyStartVariationDate.getFullYear();
                        console.log("companyYear", companyYear)
                        // Get the score from previous or next years
                        let score = null;

                        // First, try to get a previous valid score
                        score = this.getPreviousValidScore(companyYear, company.scores);

                        // If no valid score was found, get the next valid score
                        if (score === null) {
                            score = this.getNextValidScore(companyYear, company.scores);
                        }
                        // Determine color and status based on comparison with this.seuil
                        const color = score >= this.seuil ? 1 : 0;
                        const status = score >= this.seuil ? 1 : 0;
                        // Return the company with the additional score field
                        return {
                            id: company.id,
                            name: company.name,
                            isin: company.isin,
                            symbol: company.symbol,
                            score: score,
                            color: color,
                            status: status
                        };
                    });
                    companiesWithScores = companiesWithScores.sort((a, b) => b.score - a.score);

                    this.loadedCompanies = companiesWithScores
                    console.log("companiesWithScores", companiesWithScores)
                } else {
                    alert("No companies found in the stock exchange composition!");
                }
            } else {
                alert("Please select a valid version to load from!");
            }
        } else {
            alert("No version selected!");
        }
    }
    handleRemove(company) {
        // Change the status to 0 (disabled) and color to 0 (grey)
        company.status = 0;
        company.color = 0;
    }
    // Handle the "add" action
    handleAdd(company) {
        // Change the status to 1 (active) and color to 1 (green)
        company.status = 1;
        company.color = 1;
    }
    xls() {
        ExportStockVersions("StockVersion", this.indexVersions, "StockVersions.xlsx");
    }

    resetStock() {

        this.$root.$confirm
            .open(
                "Reset Stock Exchange",
                "Are you sure you want to reset this stock exchange? This action cannot be undone.",
                {}
            )
            .then(confirm => {
                if (confirm) {
                    this.$api.get(`stock-exchange/reset-stock?id=${this.stockId}`).then(res => {
                        window.location.reload()
                    })
                }
            });
    }
    addCompany() {

        const selectedCompany = this.companies.find(company => company.id === this.companyId);
        // Check if the company with the same ID already exists in selectedCompanies
        const exists = this.selectedCompanies.some(company => company.id === this.companyId);
        this.doneUpload = false;
        // If the company doesn't exist in selectedCompanies, add it
        if (selectedCompany && !exists) {
            if (this.exist) {
                this.uploadingData = true;

                this.$api.post(`stock-exchange/add-company?index=${this.indexId}&company=${this.companyId}`).
                    then(response => {
                        this.doneUpload = true;
                    }).catch((e) => {
                        this.error = e.response.data;
                    }).finally(() => {
                        this.uploadingData = false;
                        //this.doneUpload = false;
                        //this.loadComposition();
                    });
            }
            this.selectedCompanies.push({
                id: selectedCompany.id,
                name: selectedCompany.name,
                symbol: selectedCompany.symbol
            });
        } else {
            const msg = 'company exist deja';
        }
        this.companyId = null;
        console.log('jannt', this.selectedCompanies);
    }
    deleteCompany(id) {
        this.doneUpload = false;
        this.$root.$confirm
            .open(
                "Delete company",
                "Are you sure you want to delete this company ?",
                {}
            )
            .then(confirm => {
                if (confirm) {
                    if (this.exist) {
                        this.uploadingData = true;
                        this.$api.post(`stock-exchange/remove-company?index=${this.indexId}&company=${id}`).
                            then(response => {
                                this.doneUpload = true;
                            }).catch((e) => {
                                this.error = e.response.data;
                            }).finally(() => {
                                this.uploadingData = false;
                                // this.loadComposition();
                                //this.doneUpload = false;
                            });
                    }
                    this.selectedCompanies = this.selectedCompanies.filter(company => company.id !== id);
                    if (this.selectedCompanies.length == 0) {
                        this.exist = false;
                    }
                }
            });
    }
    updateSeuil(seuil) {
        this.seuil = seuil;
    }
    uploadData() {
        let date = new Date(this.startVariation);
        date.setMinutes(date.getMinutes() - date.getTimezoneOffset());  // Adjust for timezone offset
        this.startVariation = date.toISOString().slice(0, 16); // Format as yyyy-MM-ddTHH:mm
        this.$root.$confirm
            .open(
                "Warning: Finalizing Your Composition",
                "Once saved, this stock index composition cannot be undone or modified.<br> Please ensure all information is correct before proceeding.",
                { width: '600px', color: 'warning' }
            )
            .then(confirm => {
                if (confirm) {
                    this.uploadingData = true;
                    this.update = false;

                    if (this.exist) {
                        this.update = true;
                    }
                    this.error = null;
                    this.$api.post(`stock-exchange/integrate-index?stock=${this.stockId}    `, {
                        seuil: this.seuil,
                        start_date: this.startVariation,
                        version: this.selectedIndexVersion,
                        composition: this.loadedCompanies
                    }).then(response => {
                        this.uploadingData = false;
                        this.doneUpload = true;
                        this.loadMsiComposition();
                    }).catch((e) => {
                        this.error = e.response.data;
                        this.uploadingData = false;
                    }).finally(() => {
                        // this.loadComposition();
                        this.doneUpload = false;
                        // window.location.reload();
                    });
                }
            });

    }

    importFScompanies() {
        this.uploadingData = true;

        const symbols = this.selectedCompanies.map(company => company.symbol);
        this.error = null;

        this.$api.post(`company/fmp-financial?nbrYear=1&externe=true`, {
            symbol: symbols,
        }).catch((e) => {
            this.error = e.response.data;
        }).finally(() => {
            this.uploadingData = false;
            this.doneUpload = true;
        });
    }
    importComposition() {

    }
    viewCompany(id) {
        const routePath = `/company/${id}`;
        // Use window.open to open the route in a new tab
        window.open(routePath, '_blank');
        //this.$router.push(`/company/${id}`);
    }

    viewIndex() {
        const routePath = `/stock/${this.indexId}`;
        window.open(routePath, '_blank');
    }
}
